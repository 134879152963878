import { Form } from 'antd'

import Select from 'common/components/Select'


const SelectField = ({ name, label, required, options, disabled, ...otherProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Select options={options} disabled={disabled} {...otherProps} />
    </Form.Item>
  );
};

export default SelectField;
