import { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Segmented, DatePicker, Select } from 'antd'
import dayjs from 'dayjs'

import { getObjectSearchParams, getDataQueryString } from 'common/utils/urls'

import "./styles.css"


const { RangePicker } = DatePicker;


const PeriodChooser = ({ onChange, queryKey = "period", changeNav = true }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const today = dayjs();
  const defaultValue = { period: "today", dateStart: today, dateEnd: today, group: 'day' };

  const [val, setVal] = useState(defaultValue);

  const [objectSearchParams, objectSearchString] = useMemo(() => {
    const params = getObjectSearchParams(location.search, queryKey);
    return [params, params.toString()];
  }, [location.search, queryKey]);

  const updateLocation = (value) => {
    if (changeNav) {
      Object.keys(value).forEach(key => {
        let v = value[key];
        if (key === 'dateStart' || key === 'dateEnd') {
          v = v.format('YYYY-MM-DD');
        }
        objectSearchParams.set(key, v);
      });

      // eslint-disable-next-line
      const [_, searchString] = getDataQueryString(location.search, objectSearchParams, queryKey);
      navigate({ search: searchString }, { replace: true });
    }
  }

  const _onChange = (value) => {
    if (value === null) {
      value = {
        period: objectSearchParams.get('period') || defaultValue.period,
        dateStart: objectSearchParams.get('dateStart') ? dayjs(objectSearchParams.get('dateStart')) : defaultValue.dateStart,
        dateEnd: objectSearchParams.get('dateEnd') ? dayjs(objectSearchParams.get('dateEnd')) : defaultValue.dateEnd,
        group: objectSearchParams.get('group') || defaultValue.group,
      };
      setVal(value)
    }

    onChange && onChange(value);
  }

  useEffect(() => {
    if (!changeNav) {
      _onChange(val);
    }

    // eslint-disable-next-line
  }, [changeNav, val]);

  useEffect(() => {
    if (changeNav) {
      _onChange(null);
    }
    // eslint-disable-next-line
  }, [changeNav, objectSearchString]);

  const updatePeriod = (newPeriod, newDateStart, newDateEnd) => {
    let period = newPeriod || val.period;
    let dateStart = newDateStart || val.dateStart;
    let dateEnd = newDateEnd || val.dateEnd;
    let group = val.group;

    if (newPeriod) {
      const now = dayjs();
      switch (newPeriod) {
        case "today":
          dateStart = now;
          dateEnd = now;
          break;
        case "yesterday":
          const yesterday = now.subtract(1, 'day');
          dateStart = yesterday;
          dateEnd = yesterday;
          break;
        case "week":
          dateStart = now.startOf('week');
          dateEnd = now;
          break;
        case "month":
          dateStart = now.startOf('month');
          dateEnd = now;
          break;
        case "year":
          dateStart = now.startOf('year');
          dateEnd = now;
          break;
        default:
          break;
      }

    } else if (newDateStart && newDateEnd) {
      period = 'other';

      dateStart = newDateStart;
      dateEnd = newDateEnd;
    }

    setVal({ period, dateStart, dateEnd, group });
    updateLocation({ period, dateStart, dateEnd, group });
  }

  const updateGroup = (newGroup) => {
    const newVal = { ...val, group: newGroup };
    setVal(newVal);
    updateLocation(newVal);
  }

  return (
    <div className="periodChooser">
      <Segmented
        style={{ marginRight: '16px' }}
        value={val.period}
        options={[
          { label: 'Сегодня', value: 'today' },
          { label: 'Вчера', value: 'yesterday' },
          { label: 'Неделя', value: 'week' },
          { label: 'Месяц', value: 'month' },
          { label: 'Год', value: 'year' },

          {
            label: (
              <RangePicker
                value={[val.dateStart, val.dateEnd]} 
                onChange={(dates) => updatePeriod(null, dates[0], dates[1])}
                format={{
                  format: 'DD.MM.YY',
                  //type: 'mask',
                }}
                suffixIcon={null}
              />
            ), value: 'other'
          }
        ]}
        onChange={(value) => {
          updatePeriod(value);
        }}
      />

      <Select
        style={{ marginLeft: '16px' }}
        value={val.group}
        options={[
          { label: 'По дням', value: 'day' },
          { label: 'По неделям', value: 'week' },
          { label: 'По месяцам', value: 'month' },
        ]}
        onChange={(value) => updateGroup(value)}
      />
    </div>
  )
}

export default PeriodChooser
