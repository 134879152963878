import { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { DatePicker, Button } from 'antd'
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import dayjs from 'dayjs'

import { getObjectSearchParams, getDataQueryString } from 'common/utils/urls'

import "./styles.css"


const DateChooser = ({ onChange, queryKey = "period", changeNav = true }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const today = dayjs();
  const defaultValue = today;

  const [val, setVal] = useState(defaultValue);

  const [objectSearchParams, objectSearchString] = useMemo(() => {
    const params = getObjectSearchParams(location.search, queryKey);
    return [params, params.toString()];
  }, [location.search, queryKey]);

  const updateLocation = (value) => {
    if (changeNav) {
      objectSearchParams.set('date', value.format('YYYY-MM-DD'));

      // eslint-disable-next-line
      const [_, searchString] = getDataQueryString(location.search, objectSearchParams, queryKey);
      navigate({ search: searchString }, { replace: true });
    }
  }

  const _onChange = (value) => {
    if (value === null) {
      value = objectSearchParams.get('date') ? dayjs(objectSearchParams.get('date')) : defaultValue;
      setVal(value)
    }

    onChange && onChange(value);
  }

  useEffect(() => {
    if (!changeNav) {
      _onChange(val);
    }

    // eslint-disable-next-line
  }, [changeNav, val]);

  useEffect(() => {
    if (changeNav) {
      _onChange(null);
    }
    // eslint-disable-next-line
  }, [changeNav, objectSearchString]);

  const updateDate = (newDate) => {
    console.log('updateDate', newDate);
    const date = newDate || val.date;

    setVal(date);
    updateLocation(date);
  }

  return (
    <div className="dateChooser">
      <Button onClick={() => updateDate(val.add(-1, 'day'))} shape="circle" icon={<LeftOutlined />} />

      <DatePicker
        value={val}
        onChange={updateDate}
        format={{
          format: 'DD.MM.YY',
          //type: 'mask',
        }}
        allowClear={false}
      //suffixIcon={null}
      />

      <Button onClick={() => updateDate(val.add(1, 'day'))} shape="circle" icon={<RightOutlined />} />
    </div>
  )
}

export default DateChooser
