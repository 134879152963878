import { Row, Col, Form } from 'antd'

import { FormFields } from 'common/components'

const { PasswordField } = FormFields;


const MainFieldset = () => {
  const form = Form.useFormInstance();

  const samePasswordRule = {
    message: 'Пароли должны совпадать',
    validator: (_, value) => {
      return form.getFieldValue('password') === value ? Promise.resolve() : Promise.reject('Some message here');
    }
  }

  return (
    <Row gutter={32}>
      <Col span={24}>
        <PasswordField label="Пароль" name="password" autoComplete="off" required={false} rules={[samePasswordRule]} />

        <PasswordField label="Пароль ещё раз" name="password2" autoComplete="off" required={false} rules={[samePasswordRule]} />
      </Col>
    </Row>
  )
}

export default MainFieldset;
