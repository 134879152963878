import { Card } from '@tremor/react'

import { useBillingStats } from 'store/api/statsApi'


const DigitalCard = ({ title, count, beforeCount, isLoading }) => {
  const diff = count - beforeCount;
  const percent = count !== 0 ? Math.abs((diff / (beforeCount || diff)) * 100).toFixed(1) : 0;

  return (
    <Card className="mx-auto max-w-xs">
      <h4 className="mb-2 text-tremor-default text-tremor-content">
        {title}
      </h4>
      <div className="text-tremor-metric font-semibold text-tremor-content-strong">
        {typeof count === 'number' && !isLoading ?
          Intl.NumberFormat('ru').format(count === 0 ? 0 : count).toString()
          :
          <div className="animate-pulse w-2/3 h-9 bg-gray-300 rounded"></div>}
      </div>
      {typeof diff === 'number' && !isLoading && diff !== 0 ?
        diff >= 0 ? (
          <div className="mt-2 flex items-center justify-between text-tremor-default text-green-500">
            <span>+{Intl.NumberFormat('ru').format(diff).toString()} ({percent}%)</span>
          </div>
        ) : (
          <div className="mt-2 flex items-center justify-between text-tremor-default text-red-500">
            <span>{Intl.NumberFormat('ru').format(diff).toString()} ({percent}%)</span>
          </div>
        )
        :
        isLoading && (
          <div className="mt-2 text-tremor-default text-green-500">
            <div className="animate-pulse w-1/2 h-4 bg-gray-300 rounded"></div>
          </div>
        )
      }
    </Card>
  )
}


function getSum(stats, conditionFunc) {
  return Object.keys(stats || {}).reduce((acc, key) => {
    if (conditionFunc(key)) {
      acc += stats[key];
    }
    return acc;
  }, 0);
}


const RefillStatsChart = ({ dateStart, dateEnd }) => {
  const filterParams = {
    'date_start': dateStart,
    'date_end': dateEnd,
  }
  const [stats, isLoading] = useBillingStats(filterParams);

  const refill = stats?.refill;
  const transaction = stats?.transaction;
  const saldo = stats?.saldo;

  const debit = getSum(transaction?.current, (key) => key !== 'refill') * -1;
  const debitBefore = getSum(transaction?.before, (key) => key !== 'refill') * -1;

  const debitProfile = getSum(transaction?.current, (key) => key === 'profile') * -1;
  const debitProfileBefore = getSum(transaction?.before, (key) => key === 'profile') * -1;

  const debitSalon = getSum(transaction?.current, (key) => key === 'salon') * -1;
  const debitSalonBefore = getSum(transaction?.before, (key) => key === 'salon') * -1;

  const debitSauna = getSum(transaction?.current, (key) => key === 'sauna') * -1;
  const debitSaunaBefore = getSum(transaction?.before, (key) => key === 'sauna') * -1;

  const debitJob = getSum(transaction?.current, (key) => key === 'job') * -1;
  const debitJobBefore = getSum(transaction?.before, (key) => key === 'job') * -1;

  const debitMassage = getSum(transaction?.current, (key) => key === 'massage') * -1;
  const debitMassageBefore = getSum(transaction?.before, (key) => key === 'massage') * -1;

  const debitAuction = getSum(transaction?.current, (key) => key === 'auctionupbet') * -1;
  const debitAuctionBefore = getSum(transaction?.before, (key) => key === 'auctionupbet') * -1;

  return (
    <div className="grid grid-cols-4 gap-4 mb-8">
      <DigitalCard title="Сальдо на начало" count={saldo?.startAmount} beforeCount={saldo?.startAmount} isLoading={isLoading} />
      <DigitalCard title="Сальдо на конец" count={saldo?.endAmount} beforeCount={saldo?.endAmount} isLoading={isLoading} />

      <DigitalCard title="Пополнений" count={refill?.current} beforeCount={refill?.before} isLoading={isLoading} />
      <DigitalCard title="Списаний" count={debit} beforeCount={debitBefore} isLoading={isLoading} />

      <DigitalCard title="Списаний за анкеты" count={debitProfile} beforeCount={debitProfileBefore} isLoading={isLoading} />
      <DigitalCard title="Списаний за салоны" count={debitSalon} beforeCount={debitSalonBefore} isLoading={isLoading} />
      <DigitalCard title="Списаний за сауны" count={debitSauna} beforeCount={debitSaunaBefore} isLoading={isLoading} />
      <DigitalCard title="Списаний за вакансии" count={debitJob} beforeCount={debitJobBefore} isLoading={isLoading} />
      <DigitalCard title="Списаний за массаж" count={debitMassage} beforeCount={debitMassageBefore} isLoading={isLoading} />

      <DigitalCard title="Списаний за аукцион" count={debitAuction} beforeCount={debitAuctionBefore} isLoading={isLoading} />
    </div>
  )
}

export default RefillStatsChart
