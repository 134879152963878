import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useAccountSimpleList } from 'store/api/accountApi'
import AccountValue from '../AccountValue'


export function AccountSelect({ filterParams, items, required, ...otherProps }) {
  const [accounts] = useAccountSimpleList(filterParams, items);

  const options = accounts.map(item => ({
    ...item,
    title: <AccountValue detail={item} asLink={false} showSecondary={false} />,
    searchString: `${item.user.email} ${item.user.phone} ${item.user.first_name} ${item.user.last_name}`
  }));

  return (
    <Select
      options={options}
      fieldNames={{ value: pkField }}
      optionFilterProp="searchString"
      optionRender={option => <AccountValue detail={option.data} asLink={false} />}
      labelRender={({ title }) => title}
      {...otherProps}
    />
  )
}


const AccountSelectField = ({ name, label, required, disabled, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <AccountSelect required={required} disabled={disabled} {...selectProps} />
    </Form.Item>
  );
}

AccountSelectField.getValue = (values) => {
  return values.map(value => parseInt(value));
}

const DisplayValue = ({ value, config }) => {
  console.log('DisplayValue', value);
  const [items] = useAccountSimpleList({ pk: value });
  console.log('items', items);
  const item = items.filter(item => item.pk === parseInt(value))[0];
  console.log('item', item);
  return item?.user?.email || value
}

AccountSelectField.DisplayValue = DisplayValue;

export default AccountSelectField
