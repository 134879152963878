import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import AuctionBetApi, { pkField } from 'store/api/auctionBetApi'

import { ClientValue } from 'components/Client'
import { ObjectValue } from 'components/Billing'
import AuctionStatus from 'components/Auction/AuctionStatus'
import AuctionBetValue from 'components/AuctionBet/AuctionBetValue'


const DefaultColumnList = ['name', 'object', 'user', 'betAmount', 'status'];

const FilterFields = [
  SmartFilter.getFieldConfig('status', 'Статус', SmartFilter.FilterSelect, { options: AuctionStatus.STATUS_OPTIONS }),
];


const AuctionBetList = ({ site, title, columnList, baseFilters, filterParams, onFilterChange, pageSize, showFilters = true, showActions = false }) => {
  const [apiGetList, apiGetListProps] = AuctionBetApi.endpoints.getAuctionBetList.useLazyQuery();

  const fields = {
    name: {
      title: 'Ставка',
      sorter: false,
      render: (val, item) => (<AuctionBetValue detail={item} />),
    },
    object: {
      title: 'Анкета',
      sorter: false,
      render: (val, item) => (<ObjectValue site={site} detail={val} />),
    },
    user: {
      title: 'Клиент',
      sorter: false,
      render: (val, item) => (<ClientValue detail={item.user} />),
    },
    betAmount: { title: 'Сумма', sorter: false, width: 130, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
    time: TableColumns.getDateTime({ title: 'Дата', fieldName: 'time', width: 160 }),
    status: {
      title: 'Статус',
      sorter: false,
      width: 130,
      render: (val, item) => <AuctionStatus detail={item} />,
    },
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      invalidateTags={[{ type: "AuctionBetList" }]}

      baseFilters={baseFilters}
      filterQueryKey='auctionBet'
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={false}
      hidePagination={false}
      pageSize={pageSize}
      filterFields={showFilters ? FilterFields : null}
      showActions={showActions}

      summary={(dataSource) => {
        const amount = dataSource.reduce((acc, item) => acc + parseFloat(item.betAmount), 0)

        return (
          <>
            Итого: {Intl.NumberFormat('ru').format(amount).toString()}
          </>
        )
      }}

    />
  )
}

AuctionBetList.FilterFields = FilterFields;

export default AuctionBetList;
