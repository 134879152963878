import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/countryApi'


const CountryValue = ({ detail, asLink = true, isBack = true }) => {
  return (
    <DetailValue className="flex" url={asLink && getDetailUrl(detail)} isBack={isBack}>
      {detail?.name}
    </DetailValue>
  );
};


export default CountryValue
