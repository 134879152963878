const ENV = {
  local: {
    apiUrl: 'http://localhost:8000/api',
    wsUrl: 'ws://localhost:8000/ws/',
    countryCode: 'ru',
  },
  production: {
    apiUrl: 'https://es1.treeweb.top/api',
    wsUrl: 'wss://es1.treeweb.top/ws/',
    countryCode: 'ru',
  },
};

const urls = ENV['production'];

export const API_URL = urls.apiUrl;
export const WS_URL = urls.wsUrl;
export const COUNTRY_CODE = urls.countryCode;
