import { api } from './api'


export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query(data) {
        return {
          url: 'auth/',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
    }),

    logoutUser: builder.mutation({
      query(token) {
        return {
          url: 'auth/logout/',
          method: 'POST',
          body: { token },
        };
      },
    }),

    tokenRefresh: builder.mutation({
      query(token) {
        return {
          url: 'auth/refresh/',
          method: 'POST',
          body: { token },
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
} = authApi;
