import { api, getList } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'transaction/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/billing/transactions/${detail[pkField]}/`
}


const TransactionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionList: getList(builder, baseApiUrl, 'TransactionList'),
  }),
});

export function useTransactionList(filterParams, items) {
  return useLoadedItems(TransactionApi.endpoints.getTransactionList, filterParams, items);
}

export default TransactionApi;
