import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'district/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/districts/${detail[pkField]}/`
}


const DistrictApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDistrictList: getList(builder, baseApiUrl, 'DistrictList'),
    getDistrictSimpleList: getList(builder, baseApiUrl, 'DistrictSimpleList', { simple: true }),

    getDistrictDetail: getDetail(builder, baseApiUrl, 'DistrictDetail'),
    getDistrictFormDetail: getDetail(builder, baseApiUrl, 'DistrictFormDetail', { forEdit: true }),

    updateDistrict: update(builder, baseApiUrl, pkField),

    deleteDistrict: remove(builder, baseApiUrl),
    batchDeleteDistrict: batchRemove(builder, baseApiUrl),
  }),
});

export function useDistrictSimpleList(filterParams, items) {
  return useLoadedItems(DistrictApi.endpoints.getDistrictSimpleList, filterParams, items);
}

export default DistrictApi;
