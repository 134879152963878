import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import { getFullNameOrEmail } from 'common/utils/helpers'
import ClientApi, { pkField, getDetailUrl } from 'store/api/clientApi'
import { ClientActions } from 'store/features/clientSlice'
import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const ClientForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.client);

  const [apiGetFormDetail, apiGetFormDetailProps] = ClientApi.endpoints.getClientFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ClientApi.endpoints.updateClient.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    values.phone = values.phone?.phoneNumber ? values.phone.value : '';
    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="Client"
      titleNew="Новый клиент"
      titleEdit={`${getFullNameOrEmail(formDetail)}`}

      open={activeForm === 'editForm'}
      closeFormAction={ClientActions.closeForm()}
      invalidateTags={[{ type: "ClientList" }, { type: "ClientDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  );
}


export default ClientForm
