
import { Status } from 'common/components'

const STATUS_LIST = [
  ['waiting', 'В ожидании'],
  ['completed', 'Завершено'],
  ['canceled', 'Отменено'],
]

const STATUS = STATUS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});


const STATUS_OPTIONS = [{ value: '', label: '---'}].concat(STATUS_LIST.map((item) => ({ value: item[0], label: item[1] })));


const AuctionStatus = ({ detail, style }) => {
  return (
    <>
      <Status status={detail?.status} statuses={STATUS} style={style} />
    </>

  )
}

AuctionStatus.STATUS_LIST = STATUS_LIST;
AuctionStatus.STATUS = STATUS;
AuctionStatus.STATUS_OPTIONS = STATUS_OPTIONS;

export default AuctionStatus
