import { createSlice } from '@reduxjs/toolkit'

import { parseJwt } from 'common/utils/jwt'
import { authApi } from 'store/api/authApi'


const initialState = {
  token: null,
  user: null,
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    logout: () => initialState,

    setCredentials: (state, { payload: { token, user } }) => {
      state.token = token;
      state.user = user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.loginUser.matchFulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = parseJwt(action.payload.token);
      })
      .addMatcher(authApi.endpoints.logoutUser.matchFulfilled, (state, action) => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.tokenRefresh.matchFulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = parseJwt(action.payload.token);
      })
      .addMatcher(authApi.endpoints.tokenRefresh.matchRejected, (state, action) => {
        return initialState
      })
  },
});

export default authSlice.reducer;

export const { logout, setCredentials } = authSlice.actions;
