import { useState } from 'react'

import { SmartFilter, SmartFilterValues } from 'common/components'

import PageHeader from './PageHeader'

import './styles.css'


const Page = ({ children, showFilter, filterQueryKey, onFilterChange, filterFields, ...headerProps }) => {
  const [pageContainer, setPageContainer] = useState(null);

  let cls = "page";
  if (headerProps.tabs) cls += " page__witTabs";

  return (
    <div className={cls}>
      <PageHeader
        headerMiddle={showFilter ? <SmartFilter queryKey={filterQueryKey} onChange={onFilterChange} fields={filterFields} /> : null}
        {...headerProps}
      />

      <div className="pageContent" ref={setPageContainer}>
        {showFilter ?
          <SmartFilterValues queryKey={filterQueryKey} onChange={onFilterChange} fields={filterFields} container={pageContainer} />
          : null}
        {children}
      </div>
    </div>
  );
};

export default Page
