import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/staffApi'
import { getFullName } from 'common/utils/helpers'

import './styles.css'


const StaffValue = ({ detail, asLink = true, isBack = true, showSecondary = true }) => {
  const fullName = getFullName(detail);
  return (
    <DetailValue className='userValue' url={asLink && getDetailUrl(detail)} isBack={isBack}>
      <Avatar className='userValue__icon' size={24} icon={<UserOutlined />} src={detail?.photo?.url} />
      
      <div className='userValue__name'>
        <span>{detail?.email || '-'}</span>
        {showSecondary && fullName && <div className='secondary'>{fullName}</div>}
      </div>
    </DetailValue>
  );
}

export default StaffValue
