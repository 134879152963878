import { api, getList, getDetail, update } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'refill/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/billing/refills/${detail[pkField]}/`
}


const RefillApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRefillList: getList(builder, baseApiUrl, 'RefillList'),

    getRefillDetail: getDetail(builder, baseApiUrl, 'RefillDetail'),
    getRefillFormDetail: getDetail(builder, baseApiUrl, 'RefillFormDetail', { forEdit: true }),

    updateRefill: update(builder, baseApiUrl, pkField),
  }),
});

export function useRefillList(filterParams, items) {
  return useLoadedItems(RefillApi.endpoints.getRefillList, filterParams, items);
}

export default RefillApi;
