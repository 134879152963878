import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import AuctionApi, { pkField } from 'store/api/auctionApi'

import { SiteValue } from 'components/Site'
import AuctionValue from 'components/Auction/AuctionValue'
import AuctionStatus from 'components/Auction/AuctionStatus'
import { SiteSelectField } from 'components/Site'


const DefaultColumnList = ['name', 'site', 'count', 'amount', 'status'];

const FilterFields = [
  SmartFilter.getFieldConfig('status', 'Статус', SmartFilter.FilterSelect, { options: AuctionStatus.STATUS_OPTIONS }),
  SmartFilter.getFieldConfig('site', 'Сайт', SiteSelectField, { size: 'default' }),
];


const AuctionList = ({ title, columnList, baseFilters, filterParams, onFilterChange, pageSize, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = AuctionApi.endpoints.getAuctionList.useLazyQuery();

  const fields = {
    name: {
      title: 'Номер',
      sorter: false,
      width: 240,
      render: (val, item) => (<AuctionValue detail={item} asLink={true} />),
    },
    site: {
      title: 'Сайт',
      sorter: false,
      render: (val, item) => (<SiteValue detail={item.site} asLink={false} />),
    },
    amount: { title: 'Сумма', sorter: false, width: 130, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
    count: { title: 'Кол-во', sorter: false, width: 130, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
    time: TableColumns.getDateTime({ title: 'Дата', fieldName: 'time', width: 160 }),
    status: {
      title: 'Статус',
      sorter: false,
      width: 130,
      render: (val, item) => <AuctionStatus detail={item} />,
    },
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      invalidateTags={[{ type: "AuctionList" }]}

      baseFilters={baseFilters}
      filterQueryKey='auction'
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={false}
      hidePagination={false}
      pageSize={pageSize}
      filterFields={FilterFields}
      showFilters={showFilters}

      summary={(dataSource) => {
        const amount = dataSource.reduce((acc, item) => acc + parseFloat(item.amount), 0)

        return (
          <>
            Итого: {Intl.NumberFormat('ru').format(amount).toString()}
          </>
        );
      }}

    />
  )
}

AuctionList.FilterFields = FilterFields;

export default AuctionList;
