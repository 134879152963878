import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import DistrictApi, { pkField, getDetailUrl } from 'store/api/districtApi'
import { DistrictActions } from 'store/features/districtSlice'

import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const { formDetail } = useContext(Form.FormContext);

  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset formDetail={formDetail} />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const DistrictForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.district);

  const [apiGetFormDetail, apiGetFormDetailProps] = DistrictApi.endpoints.getDistrictFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = DistrictApi.endpoints.updateDistrict.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="District"
      titleNew="Новый город"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={DistrictActions.closeForm()}
      invalidateTags={[{ type: "DistrictList" }, { type: "DistrictDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  )
}


export default DistrictForm
