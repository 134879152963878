import { Row, Col } from 'antd'

import { FormFields } from 'common/components'

const { TextField, SwitchField } = FormFields;


const MainFieldset = () => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <TextField label="Email" name="email" autoComplete="off" required={true} />

        <TextField label="Имя" name="first_name" required={false} />

        <TextField label="Фамилия" name="last_name" required={false} />

        <Row gutter={32}>
          <Col span={8}>
            <SwitchField label="Активно" name="is_active" />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MainFieldset;
