import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/siteApi'

import './styles.css'


const SiteValue = ({ detail, style, asLink = true, isBack = true, replace = false }) => {
  const hiddenDomain = detail?.domain === detail?.hiddenDomain ? '' : detail?.hiddenDomain;

  return (
    <DetailValue className='siteValue' style={style} url={asLink && getDetailUrl(detail)} isBack={isBack} replace={replace}>
      <div className='siteValue__name'>
        <span>{detail?.domain || '-'}</span>
        {hiddenDomain && <div className='secondary'>{hiddenDomain}</div>}
      </div>
    </DetailValue>
  )
}


export default SiteValue
