import { FormFields } from 'common/components'
import { CountrySelectField } from 'components/Country'
import { CitySelectField } from 'components/City'

const { SelectField } = FormFields;


const CurrencyOptions = [
  { value: 'eur', label: 'Евро' },
  { value: 'usd', label: 'Доллар' },
  { value: 'rub', label: 'Рубль' },
];

const GeoFieldset = () => {
  return (
    <>
      <CountrySelectField label="Страна" name="country" required={false} />

      <CitySelectField label="Город" name="city" required={false} />

      <SelectField label="Валюта" name="currency" required={false} options={CurrencyOptions} />
    </>
  )
}

export default GeoFieldset;
