import { Button, Dropdown, Popconfirm } from 'antd'
import {
  EyeOutlined, PlusOutlined, EditOutlined, DeleteOutlined, DownloadOutlined, EllipsisOutlined, MoreOutlined,
  LockOutlined
} from "@ant-design/icons"
import { Link, useNavigate } from 'react-router-dom'

import './styles.css'


const DefaultIcons = {
  view: <EyeOutlined />,
  add: <PlusOutlined />,
  edit: <EditOutlined />,
  export: <DownloadOutlined />,
  delete: <DeleteOutlined />,

  password: <LockOutlined />,
}


const ActionMenuItem = ({ children, getUrl, onClick }) => {
  if (getUrl) {
    return (<Link to={getUrl()} onClick={onClick}>{children}</Link>)
  }

  return (<div onClick={onClick}>{children}</div>)
}


const ActionConfirm = ({ confirm, onClick, children }) => {
  return (
    <Popconfirm
      placement="bottomRight"
      title={`Уверены, что хотите «${confirm}»? `}
      okText={confirm}
      cancelText="Отмена"
      onConfirm={() => onClick()}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </Popconfirm>
  )
}


const getIcon = (icon) => {
  return typeof icon === 'string' ? DefaultIcons[icon] : icon
}


const ActionDropdown = ({ actions, type = "combined" }) => {
  const menuItems = actions.map((action, index) => {
    const btn = action.confirm ?
      <ActionConfirm confirm={action.confirm} onClick={action.onClick}>
        {action.label}
      </ActionConfirm>
      :
      <ActionMenuItem getUrl={action.getUrl} onClick={action.onClick}>{action.label}</ActionMenuItem>;


    return {
      key: `action${index}`,
      icon: getIcon(action.icon),
      label: btn,
      danger: action.danger,
    }
  });

  return (
    <Dropdown menu={{ items: menuItems }} trigger="click">
      {type === 'separated' ? <MoreOutlined /> : <Button size="small" className="ant-btn-icon-only"><EllipsisOutlined /></Button>}
    </Dropdown>
  )
}

const ActionGroup = ({ className, actions, otherActions, type = "combined", size = "small" }) => {
  let cls = `actionGroup actionGroup_${type}`;
  if (className) cls += ` ${className}`;

  const navigate = useNavigate();

  const count = actions ? actions.length : 0 + otherActions ? 1 : 0;
  if (count === 1) cls += ' actionGroup_isSingle';

  const buttons = (actions || []).map((action, index) => {
    if (!action) return null;

    const { confirm, icon, label, onClick, getUrl, component, ...btnProps } = action;

    if (component) {
      return <div key={`action${index}`} className="action__component">{label}: {component}</div>
    }

    const _onClick = (e) => {
      if (onClick) {
        e && e.preventDefault();
        onClick();
      } else if (getUrl) {
        e && e.preventDefault();
        navigate(getUrl && getUrl())
      }
    }

    return action.confirm ?
      <ActionConfirm key={`action${index}`} confirm={confirm} onClick={_onClick}>
        <Button size={size} danger={action.danger} primary={action.primary} {...btnProps}>
          {getIcon(icon)}{label}
        </Button>
      </ActionConfirm>
      :
      <Button key={`action${index}`} size={size} href={getUrl && getUrl()} onClick={_onClick}  {...btnProps}>
        {getIcon(icon)}{label}
      </Button>
  });

  return (
    <div className={cls}>
      {buttons}
      {otherActions && otherActions.length && <ActionDropdown actions={otherActions} type={type} />}
    </div>
  );
}


export default ActionGroup;
