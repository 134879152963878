import { useState } from 'react'
import { Segmented, DatePicker, Select } from 'antd'
import dayjs from 'dayjs'

import { Page } from 'common/layout'
import { DigitalStats } from 'components/Dashboard'

const { RangePicker } = DatePicker;


const HomePage = () => {
  const today = dayjs();

  const [period, setPeriod] = useState("today");
  const [dateStart, setDateStart] = useState(today);
  const [dateEnd, setDateEnd] = useState(today);
  const [group, setGroup] = useState('day');

  const updatePeriod = (newPeriod, newDateStart, newDateEnd) => {
    if (newPeriod) {
      const now = dayjs();

      switch (newPeriod) {
        case "today":
          setDateStart(now);
          setDateEnd(now);
          setGroup('day');
          break;
        case "yesterday":
          const yesterday = now.subtract(1, 'day');
          setDateStart(yesterday);
          setDateEnd(yesterday);
          setGroup('day');
          break;
        case "week":
          setDateStart(now.startOf('week'));
          setDateEnd(now.startOf('week').add(6, 'day'));
          setGroup('day');
          break;
        case "month":
          setDateStart(now.startOf('month'));
          setDateEnd(now);
          setGroup('day');
          break;
        case "year":
          setDateStart(now.startOf('year'));
          setDateEnd(now);
          setGroup('month');
          break;
        default:
          break;
      }
    } else if (newDateStart && newDateEnd) {
      const days = newDateEnd.diff(newDateStart, 'day');
      setDateStart(newDateStart);
      setDateEnd(newDateEnd);
      if (days <= 1) {
        setGroup('hour');
      } else if (group === 'hour') {
        setGroup('day');
      }
      setPeriod('');
    }
  }

  return (
    <Page
      title="Рабочий стол"
      headerMiddle={
        <>
          <Segmented
            style={{ marginRight: '16px' }}
            defaultValue={period}
            options={[
              { label: 'Сегодня', value: 'today' },
              { label: 'Вчера', value: 'yesterday' },
              { label: 'Неделя', value: 'week' },
              { label: 'Месяц', value: 'month' },
              { label: 'Год', value: 'year' }
            ]}
            onChange={(value) => {
              updatePeriod(value);
            }}
          />

          <RangePicker value={[dateStart, dateEnd]} onChange={(dates) => updatePeriod(null, dates[0], dates[1])} />

          <Select
            style={{ marginLeft: '16px' }}
            value={group}
            options={[
              { label: 'По часам', value: 'hour' },
              { label: 'По дням', value: 'day' },
              { label: 'По неделям', value: 'week' },
              { label: 'По месяцам', value: 'month' },
            ]}
            onChange={(value) => setGroup(value)}
          />
        </>
      }
    >
      <DigitalStats dateStart={dateStart.format("YYYY-MM-DD")} dateEnd={dateEnd.format("YYYY-MM-DD")} />
    </Page >
  );
};

export default HomePage
