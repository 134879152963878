import classNames from 'classnames'
import { Link } from 'react-router-dom'

import './styles.css'


export default function Logo({ className, src }) {
  const cx = classNames(['logo', className]);

  return (
    <Link className={cx} to="/">
      <div className="logo__text">
        <div className="logo__title">ES1</div>
        <div className="logo__subtitle">Аналитика</div>
      </div>
    </Link>
  );
}
