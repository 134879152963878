import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useStaffSimpleList } from 'store/api/staffApi'
import StaffValue from '../StaffValue'


export function StaffSelect({ filterParams, items, required, ...otherProps }) {
  const [staffs] = useStaffSimpleList(filterParams, items);

  const options = staffs.map(item => ({
    ...item,
    title: <StaffValue detail={item} asLink={false} showSecondary={false} />,
    searchString: `${item.email} ${item.phone} ${item.first_name} ${item.last_name}`
  }));

  return (
    <Select
      options={options}
      fieldNames={{ value: pkField }}
      optionFilterProp="searchString"
      optionRender={option => <StaffValue detail={option.data} asLink={false} />}
      labelRender={({ title }) => title}
      {...otherProps}
    />
  )
}


const StaffSelectField = ({ name, label, required, disabled, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <StaffSelect required={required} disabled={disabled} {...selectProps} />
    </Form.Item>
  );
}

StaffSelectField.getValue = (values) => {
  return values;
}

StaffSelectField.getDisplayValue = (values, config) => {
  return values
};

export default StaffSelectField
