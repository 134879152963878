import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import DistrictApi, { pkField } from 'store/api/districtApi'
import { DistrictActions } from 'store/features/districtSlice'

import { DistrictValue } from 'components/District'


const DefaultColumnList = ['name', 'code', 'sort', 'isActive', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const DistrictList = ({ title, columnList, baseFilters, filterParams }) => {
  const [apiGetList, apiGetListProps] = DistrictApi.endpoints.getDistrictList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = DistrictApi.endpoints.updateDistrict.useMutation();
  const [apiDelete, apiDeleteProps] = DistrictApi.endpoints.deleteDistrict.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = DistrictApi.endpoints.batchDeleteDistrict.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item = null) => dispatch(DistrictActions.openForm({ name: 'editForm', pk: item && item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<DistrictValue detail={item} asLink={false} />),
    },
    code: { title: 'Код', sorter: false, width: 130 },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', name: 'District', fieldName: 'isActive', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "DistrictList" }]}

      baseFilters={baseFilters}
      filterQueryKey='district'
      filterFields={FilterFields}
      filterParams={filterParams}

      rowSelection={true}
      hidePagination={false}

      onAdd={onEdit}
    />
  );
}

export default DistrictList;
