import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useDistrictSimpleList } from 'store/api/districtApi'


export function DistrictSelect({ filterParams, items, required, noLoad, ...otherProps }) {
  const [districts] = useDistrictSimpleList(filterParams, noLoad ? [] : items);

  return (
    <Select
      options={districts}
      fieldNames={{ label: 'name', value: pkField }}
      optionFilterProp="name"
      {...otherProps}
    />
  )
}

const DistrictSelectField = ({ name, label, required, disabled, noLoad, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <DistrictSelect required={required} disabled={disabled} noLoad={noLoad} {...selectProps} />
    </Form.Item>
  );
}

DistrictSelectField.getValue = (values) => {
  return values;
}

DistrictSelectField.getDisplayValue = (values, config) => {
  return values
};

export default DistrictSelectField
