import { useState } from 'react'
import { Table, SmartFilter, SmartFilterValues, ActionButton } from 'common/components'

import './styles.css'


const SmartTable = ({
  title,
  pkField,

  fields,
  columnList,

  dataList,
  isLoading,

  apiGetList,
  apiGetListProps,
  apiBatchDelete,
  apiUpdateProps,
  apiDeleteProps,
  apiBatchDeleteProps,
  apiBatchUpdateProps,
  invalidateTags,

  baseFilters,
  filterQueryKey,

  showFilters,
  filterFields,
  filterParams,
  onFilterChange,

  showActions,
  onAdd,
  actions,
  otherActions,

  rowSelection,
  hidePagination,
  pageSize,
  expandable,
  summary,
}) => {
  const [params, setParams] = useState(null);

  const _onFilterChange = (params) => {
    setParams(params)
  }

  const _filterParams = filterParams || params;

  const showHeader = !!title || showFilters;

  let cls = 'smartTable';
  if (!showHeader) cls += ' smartTable_noHeader';


  const _actions = actions || [{ icon: 'add', label: 'Добавить', type: 'primary', size: 'default', onClick: onAdd }];

  return (
    <div className={cls}>
      {showHeader &&
        <div className="smartTable__header">
          {title && <div className="smartTable__headerTitle">{title}</div>}

          {showFilters &&
            <div className="smartTable__headerMiddle">
              <SmartFilter queryKey={filterQueryKey} onChange={onFilterChange || _onFilterChange} fields={filterFields} />
            </div>}

          {showActions && <div className="smartTable__headerEnd">
            <ActionButton type="separated" actions={_actions} otherActions={otherActions} />
          </div>}
        </div>}

      <SmartFilterValues queryKey={filterQueryKey} onChange={onFilterChange || _onFilterChange} fields={filterFields} />

      <Table
        rowKey={pkField}
        fields={fields}
        columnList={columnList}

        dataList={dataList}
        isLoading={isLoading}

        apiGetList={apiGetList}
        apiGetListProps={apiGetListProps}
        apiUpdateProps={apiUpdateProps}
        apiDeleteProps={apiDeleteProps}
        apiBatchDelete={apiBatchDelete}
        apiBatchDeleteProps={apiBatchDeleteProps}
        apiBatchUpdateProps={apiBatchUpdateProps}
        invalidateTags={invalidateTags}

        baseFilters={baseFilters}
        filterQueryKey={filterQueryKey}
        filterParams={_filterParams}

        rowSelection={rowSelection}
        hidePagination={hidePagination}
        pageSize={pageSize}
        expandable={expandable}
        summary={summary}
      />
    </div>
  );
}

export default SmartTable;
