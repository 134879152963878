import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/cityApi'


const CityValue = ({ detail, asLink = true, isBack = true, replace = false, style = null }) => {
  return (
    <DetailValue className="flex" style={style} url={asLink && getDetailUrl(detail)} isBack={isBack} replace={replace}>
      {detail?.name}
    </DetailValue>
  );
};


export default CityValue
