import { DetailValue, DateTimeValue } from 'common/components'
import { getDetailUrl } from 'store/api/auctionApi'


const AuctionValue = ({ detail, asLink = true, isBack = true, replace = false, style = null }) => {
  return (
    <DetailValue className="flex" style={style} url={asLink && getDetailUrl(detail)} isBack={isBack} replace={replace}>
      <span style={{ marginRight: '8px' }}>#{detail?.pk}:</span> <DateTimeValue date={detail?.time} />
    </DetailValue>
  );
};


export default AuctionValue
