import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useCountrySimpleList } from 'store/api/countryApi'


export function CountrySelect({ filterParams, items, required, ...selectProps }) {
  const [countries] = useCountrySimpleList(filterParams, items);

  return (
    <Select
      options={countries}
      fieldNames={{ label: 'name', value: pkField }}
      optionFilterProp="name"
      {...selectProps}
    />
  )
}


const CountrySelectField = ({ name, label, required, disabled, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <CountrySelect required={required} disabled={disabled} {...selectProps} />
    </Form.Item>
  );
}

CountrySelectField.getValue = (values) => {
  return values;
}

CountrySelectField.getDisplayValue = (values, config) => {
  return values
};

export default CountrySelectField
