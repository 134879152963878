import { useState, useEffect } from 'react'

import { FormFields } from 'common/components'

const { TextField, SwitchField } = FormFields;


const MainFieldset = ({ formDetail }) => {
  const [domain, setDomain] = useState(formDetail?.domain || '');

  useEffect(() => {
    const domain = formDetail?.domain || '';
    setDomain(domain);
  }, [formDetail?.domain, formDetail?.hiddenDomain]);

  const onDomainChange = (e) => {
    setDomain(e.target.value);
  }

  return (
    <>
      <TextField label="Домен" name="domain" required={true} onChange={onDomainChange} />

      <TextField label="Скрытый домен" name="hiddenDomain" required={false} addonAfter={domain} />

      <TextField label="Название" name="name" required={false} />

      <SwitchField label="Включено" name="nginxIsActive" />
    </>
  )
}

export default MainFieldset;
