import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'common/layout'
import { OnOff } from 'common/components'

import CityApi, { pkField } from 'store/api/cityApi'
import { CityActions } from 'store/features/citySlice'
import { CityForm, CityDetail } from 'components/City'
import { DistrictList, DistrictForm } from 'components/District'


const CityDetailPage = () => {
  const { cityPK } = useParams();

  const detailPK = cityPK;

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = CityApi.endpoints.getCityDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CityApi.endpoints.updateCity.useMutation();
  const [apiDelete, apiDeleteProps] = CityApi.endpoints.deleteCity.useMutation();

  const detail = apiGetDetailProps.data;

  const onEdit = () => {
    dispatch(CityActions.openForm({ name: 'editForm', pk: detailPK }))
  };

  const activeSwitch = (
    <OnOff
      name="City"
      detail={detail}
      pkField={pkField}
      fieldName="is_published"
      apiUpdate={apiUpdate}
      apiUpdateProps={apiUpdateProps}
    />
  );

  const detailTitle = detail?.name ? `«${detail?.name}»` : '';

  return (
    <DetailPage
      title={`Город ${detailTitle}`}
      detailPK={detailPK}
      detail={detail}

      basePath='/sites/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      actions={[
        { label: 'Активно', component: activeSwitch },
        { icon: 'edit', label: 'Редактировать', type: 'primary', onClick: () => onEdit() },
      ]}
    >
      <CityDetail detail={detail} />

      <DistrictList title="Районы" baseFilters={{ city: cityPK }} />

      <CityForm showGoToDetailButton={false} />
      <DistrictForm showGoToDetailButton={false} initials={{ city: parseInt(cityPK) }} />
    </DetailPage>
  );
}

export default CityDetailPage
