import { useSelector } from 'react-redux'
import { Form } from 'antd'

import { ImageUpload } from 'common/components'
import { filesUploadUrl } from 'store/api/filesApi'

import "./styles.css"


const ImageUploadField = ({ name, label, required, multiple, data, ...uploadProps }) => {
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const uploadData = { ...data, user: user.pk }

  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]} valuePropName="fileList">
      <ImageUpload
        action={filesUploadUrl}
        headers={{ 'Authorization': `JWT ${token}` }}
        data={uploadData}
        multiple={multiple}
        {...uploadProps}
      />
    </Form.Item>
  )
}

export default ImageUploadField;
