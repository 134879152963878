import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import { getFullNameOrEmail } from 'common/utils/helpers'
import StaffApi, { pkField, getDetailUrl } from 'store/api/staffApi'
import { StaffActions } from 'store/features/staffSlice'
import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const StaffForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.staff);

  const [apiGetFormDetail, apiGetFormDetailProps] = StaffApi.endpoints.getStaffFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = StaffApi.endpoints.updateStaff.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="Staff"
      titleNew="Новый администратор"
      titleEdit={`${getFullNameOrEmail(formDetail)}`}

      open={activeForm === 'editForm'}
      closeFormAction={StaffActions.closeForm()}
      invalidateTags={[{ type: "StaffList" }, { type: "StaffDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  );
}


export default StaffForm
