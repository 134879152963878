import dayjs from 'dayjs'


function getFormatted(date, format = null) {
  return dayjs.utc(date).tz("Europe/Moscow").format(format || 'DD.MM.YYYY HH:mm:ss');
}


const DateTimeValue = ({ className, date, onlyDate, format = null }) => {
  let text = '';

  if (!date) {
    text = '-';
  } else {
    if (!format) {
      format = onlyDate ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm:ss';
    }
    text = getFormatted(date, format);
  }

  return <span className={className}>{text}</span>;
}

DateTimeValue.getFormatted = getFormatted;

export default DateTimeValue;
