import { api, getList } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const StatsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStats: getList(builder, 'stats/', 'Stats'),
    getBillingStats: getList(builder, 'stats/billing/', 'BillingStats'),
    getRefillStats: getList(builder, 'refill/stats/', 'RefillStats'),
    getTransactionStats: getList(builder, 'transaction/stats/', 'TransactionStats'),
  }),
});

export function useStats(filterParams, items) {
  return useLoadedItems(StatsApi.endpoints.getStats, filterParams, items);
}

export function useBillingStats(filterParams, items) {
  return useLoadedItems(StatsApi.endpoints.getBillingStats, filterParams, items);
}

export function useRefillStats(filterParams, items) {
  return useLoadedItems(StatsApi.endpoints.getRefillStats, filterParams, items);
}

export function useTransactionStats(filterParams, items) {
  return useLoadedItems(StatsApi.endpoints.getTransactionStats, filterParams, items);
}

export default StatsApi;
