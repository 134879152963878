import { ExportOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom'


const TYPES = {
  "profile": 'Анкета',
  "job": 'Работа',
  "salon": 'Салон',
  "sauna": 'Сауна',
  "massage": 'Массаж',
  "auctionupbet": 'Аукционная ставка',
  "refill": 'Пополнение',
}

const ObjectValue = ({ site, detail, style = null }) => {
  if (!detail) return null;

  const typeName = TYPES[detail?.type] || detail.type;
  const name = detail?.name ? `«${detail?.name}»`: `#${detail.pk}`;

  let url = '';

  if (detail.url) {
    url = site?.domain ? `https://${site?.domain}${detail.url}` : detail.url;
  } else if (detail?.type === 'auctionupbet') {
    url = `/billing/auctions/${detail.auction}/`;
  }

  if (url) {
    return (
      <Link to={url} target="_blank" style={style}>
        <ExportOutlined /> {typeName} {name}
      </Link>
    )
  }
  return (
    <span style={style}>
      {typeName} {name}
    </span>
  )
}


export default ObjectValue
