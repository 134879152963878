import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'city/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  const countryPK = detail.country?.pk || detail.country;
  return `/settings/countries/${countryPK}/${detail[pkField]}/`
}


const CityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCityList: getList(builder, baseApiUrl, 'CityList'),
    getCitySimpleList: getList(builder, baseApiUrl, 'CitySimpleList', { simple: true }),

    getCityDetail: getDetail(builder, baseApiUrl, 'CityDetail'),
    getCityFormDetail: getDetail(builder, baseApiUrl, 'CityFormDetail', { forEdit: true }),

    updateCity: update(builder, baseApiUrl, pkField),

    deleteCity: remove(builder, baseApiUrl),
    batchDeleteCity: batchRemove(builder, baseApiUrl),
  }),
});

export function useCitySimpleList(filterParams, items) {
  return useLoadedItems(CityApi.endpoints.getCitySimpleList, filterParams, items);
}

export default CityApi;
