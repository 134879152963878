import { Select as AntdSelect } from 'antd'

import "./styles.css"


const Select = ({ options, fieldNames, placeholder = "Выберите", showSearch = true, allowClear = true, size = "large", ...otherProps }) => {
  return (
    <AntdSelect
      placeholder={placeholder}
      showSearch={showSearch}
      allowClear={allowClear}
      size={size}
      options={options}
      fieldNames={fieldNames}
      {...otherProps}
    />
  )
}

export default Select
