import { FormFields } from 'common/components'

const { TextField } = FormFields;


const ContactsFieldset = () => {
  return (
    <>
      <TextField label="Email" name="contactEmail" required={false} />
      
      <TextField label="Телефон" name="contactPhone" required={false} />
      
      <TextField label="Whatsapp" name="contactWhatsapp" required={false} />

      <TextField label="Telegram" name="contactTelegram" required={false} />
    </>
  )
}

export default ContactsFieldset;
