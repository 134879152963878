import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { api } from './api/api'
import app from './features/appSlice'
import auth from './features/authSlice'
import staff from './features/staffSlice'
import client from './features/clientSlice'
import country from './features/countrySlice'
import city from './features/citySlice'
import district from './features/districtSlice'
import site from './features/siteSlice'
import refill from './features/refillSlice'
import auction from './features/auctionSlice'


const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app,
  auth,
  staff,
  client,
  country,
  city,
  district,
  site,
  refill,
  auction,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  /*middleware: [
    thunk,
    api.middleware, 
  ],*/

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
})

export const persistor = persistStore(store)
