import { api, getList, getDetail, update } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'auction/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/billing/auctions/${detail[pkField]}/`
}


const AuctionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAuctionList: getList(builder, baseApiUrl, 'AuctionList'),

    getAuctionDetail: getDetail(builder, baseApiUrl, 'AuctionDetail'),
    getAuctionFormDetail: getDetail(builder, baseApiUrl, 'AuctionFormDetail', { forEdit: true }),

    updateAuction: update(builder, baseApiUrl, pkField),
  }),
});

export function useAuctionList(filterParams, items) {
  return useLoadedItems(AuctionApi.endpoints.getAuctionList, filterParams, items);
}

export default AuctionApi;
