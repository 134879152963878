import { useEffect, useMemo } from 'react'


export function useLoadedItems(apiEndpoint, filterParams, items = null) {
  const [apiGetList, apiGetListProps] = apiEndpoint.useLazyQuery();

  const filterParamsString = JSON.stringify(filterParams);

  useEffect(() => {
    if (items === null || items === undefined) {
      const params = { ...filterParams };
      apiGetList({ params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParamsString, items]);

  const loadedItems = useMemo(() => {
    if (items !== null && items !== undefined) return items;
    if (apiGetListProps.isSuccess) {
      if (Array.isArray(apiGetListProps.data)) return apiGetListProps.data;
      if (apiGetListProps.data?.results) return apiGetListProps.data.results;
      return apiGetListProps.data || [];
    }
    return []
  }, [items, apiGetListProps.isSuccess, apiGetListProps.data])

  return [loadedItems, apiGetListProps.isFetching];
}


export function useLoadedDetail(apiEndpoint, pk, detail = null) {
  const [apiGetDetail, apiGetDetailProps] = apiEndpoint.useLazyQuery();

  useEffect(() => {
    if (detail === null || detail === undefined) {
      apiGetDetail(pk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pk, detail]);

  const loadedDetail = useMemo(() => {
    if (detail !== null && detail !== undefined) return detail;
    if (apiGetDetailProps.isSuccess) {
      return apiGetDetailProps.data;
    }
    return []
  }, [detail, apiGetDetailProps.isSuccess, apiGetDetailProps.data])

  return [loadedDetail, apiGetDetailProps.isFetching];
}
