import { Row, Col } from 'antd'

import { Details, DateTimeValue } from 'common/components'
import { SiteValue } from 'components/Site'
import AuctionStatus from 'components/Auction/AuctionStatus'


const AuctionDetail = ({ detail }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Details>
          <Details.Item label="Номер">{detail?.pk}</Details.Item>

          <Details.Item label="Дата"><DateTimeValue date={detail?.time}/></Details.Item>

          <Details.Item label="Сайт"><SiteValue detail={detail?.site}/></Details.Item>

          <Details.Item label="Статус"><AuctionStatus detail={detail}/></Details.Item>
        </Details>
      </Col>
    </Row>
  );
}

export default AuctionDetail
