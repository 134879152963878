import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import SiteApi, { pkField, getDetailUrl } from 'store/api/siteApi'
import { SiteActions } from 'store/features/siteSlice'

import { SiteValue } from 'components/Site'
import { CountryValue } from 'components/Country'
import { CityValue } from 'components/City'


const DefaultColumnList = ['domain', 'name', 'geo', 'nginxIsActive', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('nginxIsActive', 'Активно', SmartFilter.FilterBool),
];


const SiteList = ({ title, columnList, baseFilters, filterParams, onFilterChange, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = SiteApi.endpoints.getSiteList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = SiteApi.endpoints.updateSite.useMutation();
  const [apiDelete, apiDeleteProps] = SiteApi.endpoints.deleteSite.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = SiteApi.endpoints.batchDeleteSite.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item) => dispatch(SiteActions.openForm({ name: 'editForm', pk: item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const fields = {
    domain: {
      title: 'Домен',
      sorter: true,
      render: (val, item) => (<SiteValue detail={item} />),
    },
    name: { title: 'Название', sorter: false, width: 130 },
    geo: {
      title: 'Гео', sorter: false, width: 130,
      render: (val, item) => (
        <>
          <CountryValue detail={item.country} />
          <CityValue detail={item.city} />
        </>
      )
    },
    nginxIsActive: TableColumns.getColumnOnOff({ title: 'Активно', name: 'Site', fieldName: 'nginxIsActive', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'view', getUrl: () => getDetailUrl(item) },
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  }

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "SiteList" }]}

      baseFilters={baseFilters}
      filterQueryKey='site'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={true}
      hidePagination={false}
    />
  )
}

SiteList.FilterFields = FilterFields;

export default SiteList;
