import { Row, Col } from 'antd'

import { Details } from 'common/components'


const ClientDetail = ({ detail }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Details>
          <Details.Item label="Email">{detail?.email}</Details.Item>

          <Details.Item label="Баланс">{detail?.balance}</Details.Item>
        </Details>
      </Col>
    </Row>
  );
}

export default ClientDetail
