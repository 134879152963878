import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"

import { DrawerForm } from 'common/components'
import { api } from 'store/api/api'


const DrawerModelForm = ({
  name,
  titleNew = "Новый элемент",
  titleEdit = "Редактирование",
  open,
  showGoToDetailButton = true,

  pkField,
  getDetailUrl,
  closeFormAction,

  initialValues,
  prepareFormValues,
  formDetailPK,

  apiGetFormDetail,
  apiGetFormDetailProps,
  apiUpdateProps,
  invalidateTags,

  onFinish,

  children,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => {
    apiUpdateProps.reset();
    dispatch(closeFormAction);
  }

  const onSuccess = (savedData, isRedirect = false) => {
    if (isRedirect) {
      navigate(getDetailUrl(savedData));
    } else if (invalidateTags) {
      dispatch(api.util.invalidateTags(invalidateTags))
    }
    onClose();
  }

  return (
    <DrawerForm
      name={name}
      title={formDetailPK ? titleEdit : titleNew}
      open={open}

      pkField={pkField}
      detailPK={formDetailPK}
      detail={apiGetFormDetailProps.data}
      detailIsLoading={apiGetFormDetailProps.isFetching}
      onDetailLoad={(pk) => apiGetFormDetail(pk)}
      prepareFormValues={prepareFormValues}

      savedData={apiUpdateProps.data}
      isSaving={apiUpdateProps.isLoading}
      isSaved={apiUpdateProps.isSuccess}
      errorData={apiUpdateProps.error?.data}

      showGoToDetailButton={showGoToDetailButton}

      initialValues={initialValues}
      onFinish={onFinish}
      onClose={onClose}
      onSuccess={onSuccess}
    >
      {children}
    </DrawerForm>
  );
}


export default DrawerModelForm
