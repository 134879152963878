import { useState } from 'react'

import { Page } from 'common/layout'
import { DateChooser, SmartFilter } from 'common/components'
import { AuctionList } from 'components/Auction'


const BillingAuctionPage = () => {
  const [date, setDate] = useState(null);
  const [filterParams, setFilterParams] = useState({ status: 'completed' });

  const onDateChange = (newDate) => {
    setDate(newDate);
  }

  const baseFilters = {
    date_start: date?.format("YYYY-MM-DD"),
    date_end: date?.format("YYYY-MM-DD"),
    limit: 1000,
  }

  const onFilterChange = (params) => {
    setFilterParams(params)
  }

  return (
    <Page
      title="Аукционы"
      headerMiddle={
        <>
          <DateChooser onChange={onDateChange} />
          <SmartFilter
            queryKey="auction"
            onChange={onFilterChange}
            quickFields={AuctionList.FilterFields}
            initials={{ status: 'completed' }}
            showSerach={false}
          />
        </>
      }
      showFilter={true}
    >
      {date && (
        <>
          <AuctionList
            baseFilters={baseFilters}
            filterParams={filterParams}
            showFilters={false}
            pageSize={1000}
            onFilterChange={onFilterChange}
          />
        </>
      )}
    </Page >
  )
}

export default BillingAuctionPage
