import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/ru_RU'

import { appInit } from 'store/features/appSlice'
import { AppLayout } from 'common/layout'
import LoginPage from 'pages/LoginPage'
import HomePage from 'pages/HomePage'
import Page404 from 'pages/Page404'
import CountryListPage from 'pages/CountryListPage'
import CountryDetailPage from 'pages/CountryDetailPage'
import CityDetailPage from 'pages/CityDetailPage'
import StaffListPage from 'pages/StaffListPage'
import ClientListPage from 'pages/ClientListPage'
import ClientDetailPage from 'pages/ClientDetailPage'
import { BillingLayout } from 'components/Billing'
import BillingPage from 'pages/BillingPage'
import BillingAccountPage from 'pages/BillingAccountPage'
import BillingRefillPage from 'pages/BillingRefillPage'
import BillingTransactionPage from 'pages/BillingTransactionPage'
import BillingAuctionPage from 'pages/BillingAuctionPage'
import BillingAuctionDetailPage from 'pages/BillingAuctionDetailPage'

import "antd/dist/reset.css"

const COLORS = {
  colorPrimary: 'var(--ant-primary-color)',
  colorPrimaryHover: 'var(--ant-primary-color-hover)',
  colorPrimaryActive: 'var(--ant-primary-color-active)',
};


const ProtectedRoute = ({ allowedRoles }) => {
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const location = useLocation();

  return token && (user?.is_superuser || user?.is_staff) ? (
    <Outlet />
  ) : token && user ? (
    <Navigate to='/' replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInit());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        components: {
          Radio: COLORS,
          Button: COLORS,
          Switch: {
            colorPrimary: 'var(--ant-success-color)',
            colorPrimaryHover: 'var(--ant-success-color-hover)',
            colorPrimaryActive: 'var(--ant-success-color-active)',
            handleSize: 24,
            innerMaxMargin: 30,
            trackHeight: 28,
            trackMinWidth: 64,
            trackPadding: 2,
          },
          Tabs: COLORS,
          Pagination: COLORS,
          colorLink: 'var(--ant-primary-color)',
        },
        token: {
          colorLink: 'var(--ant-primary-color)',
          colorLinkHover: 'var(--ant-primary-color-hover)',
          colorLinkActive: 'var(--ant-primary-color-active)',
        }
      }}
    >
      <Routes>
        <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<HomePage />} />

            <Route path="/clients/">
              <Route index element={<ClientListPage />} />
              <Route path=":clientPK/" element={<ClientDetailPage />} />
            </Route>

            <Route path="/billing/" element={<BillingLayout />}>
              <Route index element={<BillingPage />} />
              <Route path="accounts/" element={<BillingAccountPage />} />
              <Route path="refills/" element={<BillingRefillPage />} />
              <Route path="transactions/" element={<BillingTransactionPage />} />
              <Route path="auctions/" element={<BillingAuctionPage />} />
              <Route path='auctions/:auctionPK/' element={<BillingAuctionDetailPage />} />
            </Route>

            <Route path="/settings/">
              <Route path='countries/' element={<CountryListPage />} />
              <Route path='countries/:countryPK/' element={<CountryDetailPage />} />
              <Route path='countries/:countryPK/:cityPK/' element={<CityDetailPage />} />

              <Route path='staffs/' element={<StaffListPage />} />
            </Route>
          </Route>
        </Route>

        <Route path='/login/' element={<LoginPage />} />

        <Route path='*' element={<Page404 />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
