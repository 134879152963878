import { useEffect, useMemo, createContext, useContext } from 'react'
import { Form as AntdForm, Flex, Button, Dropdown, Spin, Collapse } from 'antd'
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

import { FormError } from 'common/components'

import "./styles.css"


/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '«${label}» обязательное поле',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


const FormStates = {
  UNINITIALIZED: 'uninitialized',
  LOADING: 'loading',
  READY: 'ready',
  SUBMITED: 'submited',
  SUBMITED_AND_REDIRECT: 'submited_and_redirect',
  SUCCESS: 'success',
  ERROR: 'error',
}

const FormContext = createContext(null);


const Fieldsets = ({ fieldsets, defaultActiveKey }) => {
  const { errorFieldNames } = useContext(FormContext);

  const clonedFieldsets = useMemo(() => {
    return fieldsets.map(fieldset => {
      let clonedFieldset = { ...fieldset };

      const requiredFields = fieldset.requiredFields || [];
      const hasError = errorFieldNames ? requiredFields.some(fieldName => errorFieldNames.has(fieldName)) : false;

      if (requiredFields.length) {
        delete clonedFieldset.requiredFields;
        clonedFieldset.forceRender = true;
        clonedFieldset.className = 'required';
        clonedFieldset.extra = hasError ?
          <InfoCircleOutlined style={{ color: 'var(--ant-warning-color)' }} /> :
          <CheckCircleOutlined style={{ color: 'var(--ant-success-color)' }} />;
      }

      return clonedFieldset
    })
  }, [fieldsets, errorFieldNames]);

  if (clonedFieldsets.length > 1) {
    return (
      <Collapse items={clonedFieldsets} bordered={false} defaultActiveKey={defaultActiveKey} expandIconPosition="end" />
    )
  }

  return clonedFieldsets[0].children;
}


const Form = ({
  name,
  form,
  formDetail,
  initialValues,
  values,

  pkField,
  detailPK,
  isLoading,

  errorData,
  errorFieldNames,

  onFinish,
  onFinishFailed,

  children
}) => {
  // set errors
  useEffect(() => {
    if (errorData) {
      const fields = Object.keys(errorData || {}).map(fieldName => {
        return { name: fieldName, errors: errorData[fieldName] }
      })
      form.setFields(fields);
    }
    // eslint-disable-next-line
  }, [errorData]);

  return (
    <AntdForm
      name={name}
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={values => {
        onFinish && onFinish({ ...values, [pkField]: detailPK });
      }}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={validateMessages}
    >
      <FormContext.Provider value={{ form, formDetail, initialValues, values, errorFieldNames }}>
        <Spin spinning={isLoading}>
          <FormError error={errorData} />
          {children}
        </Spin>
      </FormContext.Provider>
    </AntdForm>
  )
}

Form.FormContext = FormContext;
Form.States = FormStates;

Form.ActionButtons = ({ isLoading, onSubmit, onCancel, submittable, submitTitle = 'Сохранить', showGoToDetailButton = true }) => {
  const saveButton =
    showGoToDetailButton ?
      <Dropdown.Button
        type="primary"
        onClick={() => onSubmit(FormStates.SUBMITED)}
        loading={isLoading}
        disabled={!submittable}
        menu={{
          items: [
            {
              key: 'default', label: `${submitTitle} и показать детали`, onClick: (e) => {
                onSubmit(FormStates.SUBMITED_AND_REDIRECT);
              }
            }
          ],
        }}
      >
        {submitTitle}
      </Dropdown.Button>
      :
      <Button type="primary" loading={isLoading} onClick={() => onSubmit(FormStates.SUBMITED)} disabled={!submittable}>
        {submitTitle}
      </Button>;


  return (
    <Flex className="formActionButtons" gap="small" justify="flex-end">
      <Button type="text" onClick={onCancel} disabled={isLoading}>Отмена</Button>
      {saveButton}
    </Flex>
  )
}

Form.Fieldsets = Fieldsets;

export default Form;
