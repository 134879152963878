import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'store/api/cityApi'


const initialState = {
  ...baseModelState,
}


export const city = createSlice({
  name: 'city',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateCity);
  },
});

export default city.reducer;

export const CityActions = city.actions;
