import { api, getList } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'account/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/billing/account/${detail[pkField]}/`
}


const AccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountList: getList(builder, baseApiUrl, 'AccountList'),

    getAccountSimpleList: getList(builder, baseApiUrl, 'AccountSimpleList', { simple: true }),
  }),
});

export function useAccountSimpleList(filterParams, items) {
  return useLoadedItems(AccountApi.endpoints.getAccountSimpleList, filterParams, items);
}

export default AccountApi;
