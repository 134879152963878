import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'country/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/settings/countries/${detail[pkField]}/`
}


const CountryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountryList: getList(builder, baseApiUrl, 'CountryList'),
    getCountrySimpleList: getList(builder, baseApiUrl, 'CountrySimpleList', { simple: true }),

    getCountryDetail: getDetail(builder, baseApiUrl, 'CountryDetail'),
    getCountryFormDetail: getDetail(builder, baseApiUrl, 'CountryFormDetail', { forEdit: true }),

    updateCountry: update(builder, baseApiUrl, pkField),

    deleteCountry: remove(builder, baseApiUrl),
    batchDeleteCountry: batchRemove(builder, baseApiUrl),
  }),
});

export function useCountrySimpleList(filterParams, items) {
  return useLoadedItems(CountryApi.endpoints.getCountrySimpleList, filterParams, items);
}

export default CountryApi;
