import React from 'react'
import { Row, Col } from 'antd'
import classNames from 'classnames'

import "./styles.css"


const DetailsItem = ({ className, size, type, defaultSpan, span, gutter, label, labelWidth, children }) => {
  const cx = classNames([
    "detailsItem",
    size && `detailsItem_${size}`,
    type && `detailsItem_${type}`,
    className,
  ]);

  const cxContent = classNames([
    "detailsItem__content",
    (children === undefined || children === null || children.length === 0) && "detailsItem__content_empty",
  ]);

  return (
    <Col className={cx} span={span || defaultSpan} gutter={gutter}>
      <div className="detailsItem__wrap">
        {label && <div className="detailsItem__label" style={{ width: `${labelWidth}px` }}>{label}</div>}
        <div className={cxContent}>{children}</div>
      </div>
    </Col>
  );
};


const Details = ({ className, title, headerEnd, gutter, labelWidth = 100, itemsType = "inline", children }) => {
  const withHeader = !!title || !!headerEnd;

  const cx = classNames(["details", withHeader && "details_withHeader", className]);

  const defaultGutter = itemsType === "block" ? [16, 20] : [16, 8];
  const _gutter = gutter || defaultGutter;

  const childrenWithExtraProp = React.Children.map(children, child => {
    return child && React.cloneElement(child, {
      defaultSpan: 24,
      _gutter,
      viewType: itemsType,
      labelWidth,
    });
  });

  return (
    <div className={cx}>
      {withHeader && (
        <div className="details__header">
          <div className="details__headerMain">
            <div className="details__title">{title}</div>
          </div>
          {headerEnd && <div className="details__headerEnd">{headerEnd}</div>}
        </div>
      )}

      <Row className="details__content" type="flex" gutter={_gutter}>
        {childrenWithExtraProp}
      </Row>
    </div>
  );
};

Details.Item = DetailsItem;

export default Details;
