import { CheckCircleFilled, StopOutlined } from '@ant-design/icons'

import './styles.css'


const BoolValue = ({ value, className }) => {
  let cls = 'boolValue';
  if (value) cls += ` boolValue_isTrue`;
  if (className) cls += ` ${className}`;

  return (
    <div className={cls}>
      {value ? <CheckCircleFilled /> : <StopOutlined />}
    </div>
  );
};

export default BoolValue;
