import { Form, Switch } from 'antd'

import "./styles.css"


const SwitchField = ({ name, label }) => {
  return (
    <Form.Item className="switch" label={label} name={name} valuePropName="checked">
      <Switch
        checkedChildren="вкл"
        unCheckedChildren="выкл"
      />
    </Form.Item>
  );
};

export default SwitchField;
