import { useState } from "react";

import { Page } from "common/layout";
import { SmartFilter } from "common/components";
import { AccountList } from "components/Account";

const BillingAccountPage = () => {
  const [filterParams, setFilterParams] = useState({});

  const onFilterChange = (params) => {
    setFilterParams(params);
  };

  return (
    <Page
      title="Счета"
      headerMiddle={
        <>
          <SmartFilter
            queryKey="account"
            onChange={onFilterChange}
            fields={AccountList.FilterFields}
            showSerach={true}
          />
        </>
      }
      showFilter={true}
    >
      <AccountList
        pageSize={50}
        filterParams={filterParams}
        showFilters={false}
        onFilterChange={onFilterChange}
      />
    </Page>
  );
};

export default BillingAccountPage;
