import { Outlet } from "react-router-dom"
import { Layout } from 'antd'

import { BillingNavigation } from 'components/Billing'

import './styles.css'


const BillingSider = () => {
  return (
    <Layout.Sider
      className='billingSider'
      collapsed={false}
      breakpoint='lg'
      width='220'
      collapsedWidth='80'
      collapsible={false}
    >
      <BillingNavigation />
    </Layout.Sider>
  )
}


const BillingLayout = () => {
  return (
    <>
      <BillingSider />

      <Outlet />
    </>
  )
}

export default BillingLayout;
