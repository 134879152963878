import { useState } from "react";

import { Page } from "common/layout";
import { DateChooser, SmartFilter } from "common/components";
import { RefillList } from "components/Refill";


const BillingRefillPage = () => {
  const [date, setDate] = useState(null);
  const [filterParams, setFilterParams] = useState(null);

  const onDateChange = (newDate) => {
    console.log("onPeriodChange", newDate);
    setDate(newDate);
  };

  const baseFilters = {
    date_start: date?.format("YYYY-MM-DD"),
    date_end: date?.format("YYYY-MM-DD"),
    limit: 500,
  };

  const onFilterChange = (params) => {
    setFilterParams(params);
  };

  return (
    <Page
      title="Пополнения"
      headerMiddle={
        <>
          <DateChooser onChange={onDateChange} />
          <SmartFilter
            queryKey="refill"
            onChange={onFilterChange}
            fields={RefillList.FilterFields}
            showSerach={false}
          />
        </>
      }
    >
      {date && (
        <>
          <RefillList
            baseFilters={baseFilters}
            pageSize={500}
            filterParams={filterParams}
            showFilters={false}
            onFilterChange={onFilterChange}
          />
        </>
      )}
    </Page>
  );
};

export default BillingRefillPage;
