import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import StaffApi, { pkField } from 'store/api/staffApi'
import { StaffActions } from 'store/features/staffSlice'

import { StaffValue } from 'components/Staff'


const DefaultColumnList = ['email', 'is_active', 'is_superuser', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('is_active', 'Активно', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('is_superuser', 'Суперпользователь', SmartFilter.FilterBool),
];


const StaffList = ({ title, columnList, baseFilters, filterParams, onFilterChange, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = StaffApi.endpoints.getStaffList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = StaffApi.endpoints.updateStaff.useMutation();
  const [apiDelete, apiDeleteProps] = StaffApi.endpoints.deleteStaff.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = StaffApi.endpoints.batchDeleteStaff.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item) => dispatch(StaffActions.openForm({ name: 'editForm', pk: item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const onPasswordChange = (item) => dispatch(StaffActions.openForm({ name: 'passwordChangeForm', pk: item[pkField] }));

  const fields = {
    email: {
      title: 'Пользователь',
      sorter: true,
      render: (val, item) => (<StaffValue detail={item} asLink={false} />),
    },
    is_active: TableColumns.getColumnOnOff({ title: 'Активно', name: 'Staff', fieldName: 'is_active', pkField, apiUpdate, apiUpdateProps }),
    is_superuser: TableColumns.getColumnOnOff({ title: 'Суперпользователь', name: 'Staff', fieldName: 'is_superuser', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'password', onClick: () => onPasswordChange(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "StaffList" }]}

      baseFilters={baseFilters}
      filterQueryKey='staff'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={true}
      hidePagination={false}
    />
  );
}

StaffList.FilterFields = FilterFields;

export default StaffList;
