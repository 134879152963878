import { ActionButton, OnOff, DateTimeValue, BoolValue } from 'common/components'


export function getColumnActions({ getActions, getOtherActions, ...props }) {
  return {
    align: 'right',
    width: 120,
    ...props,
    render: (val, item) => (
      <ActionButton 
        actions={getActions ? getActions(item) : null} 
        otherActions={getOtherActions ? getOtherActions(item) : null}
      />
    ),
  }
}


export function getColumnOnOff({ title, name, fieldName, pkField, apiUpdate, apiUpdateProps, ...props }) {
  return {
    title: title,
    width: 86,
    ...props,
    render: (val, item) => (<OnOff detail={item} name={name} pkField={pkField} fieldName={fieldName} apiUpdate={apiUpdate} apiUpdateProps={apiUpdateProps} />),
  }
}


export function getColumnBool({ title, fieldName, ...props }) {
  return {
    title: title,
    width: 80,
    ...props,
    render: (val, item) => (<BoolValue value={item[fieldName]} />),
  }
}


export function getDateTime({ title, fieldName, onlyDate, size, format, ...props }) {
  return {
    title: title,
    width: 100,
    ...props,
    render: (val, item) => (<DateTimeValue date={item[fieldName]} onlyDate={onlyDate} size={size} format={format} />),
  }
}
