import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import { getFullNameOrEmail } from 'common/utils/helpers'
import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const PasswordChangeForm = ({
  getFormDetailEndpoint,
  updateEndpoint,
  reducerName,
  closeFormAction,
  invalidateTags,
  pkField,
  getDetailUrl,
}) => {
  const { activeForm, formDetailPK } = useSelector(state => state[reducerName]);

  const [apiGetFormDetail, apiGetFormDetailProps] = getFormDetailEndpoint.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = updateEndpoint.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const formProps = {
    showGoToDetailButton: false, pkField, getDetailUrl,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="PasswordChange"
      titleNew=""
      titleEdit={`Смена пароля для ${getFullNameOrEmail(formDetail)}`}

      open={activeForm === 'passwordChangeForm'}
      closeFormAction={closeFormAction}
      invalidateTags={invalidateTags}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  );
}


export default PasswordChangeForm
