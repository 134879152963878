import { Row, Col, Form } from 'antd'

import { FormFields } from 'common/components'
import { CountrySelectField } from 'components/Country'

const { TextField, SlugField, NumberField, SwitchField } = FormFields;


const MainFieldset = ({ formDetail }) => {
  const form = Form.useFormInstance();

  const onNameChange = (e) => {
    if (!formDetail) {
      const code = SlugField.slugify(e.target.value, 20);
      form.setFieldsValue({ code })
    }
  }

  return (
    <Row gutter={32}>
      <Col span={16}>
        <CountrySelectField label="Страна" name="country" required={true} />

        <TextField label="Название" name="name" required={true} onChange={onNameChange} />

        <SlugField label="Код" name="code" required={true} maxLength={200} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
