import { SmartTable, Table, DateTimeValue } from 'common/components'
import dayjs from 'dayjs';
import { useRefillStats } from 'store/api/statsApi'


const DefaultColumnList = ['date', 'count', 'amount'];

const FORMATS = {
  'hour': 'DD.MM HH:mm',
  'day': 'DD.MM',
  'week': 'DD.MM',
  'month': 'MMMM YYYY',
  'year': 'YYYY'
}


const RefillStatsList = ({ title, dateStart, dateEnd, group, dataList, isLoading }) => {
  const filterParams = {
    'date_start': dateStart,
    'date_end': dateEnd,
    'group': group,
    'include_users': '1',
  }
  const [rows, isFetching] = useRefillStats(filterParams, dataList);

  const dateFormat = group === 'week' ? 'DD.MM' : FORMATS[group];

  const fields = {
    date: {
      title: 'Период',
      width: 100,
      render: (val, item) => {
        if (group === 'week') {
          return (
            <>
              <DateTimeValue date={val} format={dateFormat} />-
              <DateTimeValue date={dayjs(val).add(6, 'day')} format={dateFormat} />
            </>
          )
        }
        return (
          <DateTimeValue date={val} format={dateFormat} />
        )
      },
    },
    count: { title: 'Кол-во', sorter: false, width: 100, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
    amount: { title: 'Сумма', sorter: false, width: 130, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
  };

  return (
    <SmartTable
      title={title}
      pkField={'date'}

      fields={fields}
      columnList={DefaultColumnList}

      dataList={rows}
      isLoading={isLoading || isFetching}

      rowSelection={false}
      hidePagination={true}
      actions={[]}

      expandable={{
        expandedRowRender: (record) => {
          console.log(record.users);
          const _fields = {
            user: {
              title: 'Клиент',
              width: 100,
              render: (val, item) => item.user.username,
            },
            count: { title: 'Кол-во', sorter: false, width: 100, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
            amount: { title: 'Сумма', sorter: false, width: 130, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
          };
          return (
            <Table fields={_fields} rowKey={item => item.user.pk} columnList={['user', 'count', 'amount']} dataList={record.users} hidePagination={true} />
          )
        },
        rowExpandable: (record) => record.users?.length > 0,
      }}
    />
  );
}

export default RefillStatsList;
