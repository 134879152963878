import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Form, Input, Spin } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'

import { useLoginUserMutation } from 'store/api/authApi'
import { FormError } from 'common/components'


const LoginForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const location = useLocation();

  const token = useSelector(state => state.auth.token);

  const [loginUser, { isLoading, isError, error }] = useLoginUserMutation();

  const onFinish = (values) => {
    loginUser(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (token) {
      navigate(location.state?.from || '/');
    }
  }, [token, location.state?.from, navigate]);

  const initialValues = {};

  return (
    <Form
      name="botEditForm"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Spin spinning={isLoading}>
        <FormError isError={isError} error={error} />

        <Form.Item
          label="Логин"
          name="username"
          rules={[
            {
              required: true,
              message: 'Введите логин',
            },
          ]}
        >
          <Input prefix={<UserOutlined />} size="large" />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[
            {
              required: true,
              message: 'Введите пароль',
            },
          ]}
        >
          <Input type="password" prefix={<LockOutlined />} size="large" />
        </Form.Item>

        <Button key="submit" type="primary" htmlType="submit" size="large" style={{ width: '100%' }}>
          Войти
        </Button>
      </Spin>
    </Form>
  );
};

export default LoginForm;
