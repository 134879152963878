import { useStats } from 'store/api/statsApi'

import { DigitalCard } from 'common/components'


const RefillStatsChart = ({ dateStart, dateEnd }) => {
  const filterParams = {
    'date_start': dateStart,
    'date_end': dateEnd,
  }
  const [stats, isLoading] = useStats(filterParams);

  const profile = stats?.profile;
  const user = stats?.user;

  return (
    <div className="grid grid-cols-4 gap-4 mb-8">
      <DigitalCard title="Активных анкет" count={profile?.current} beforeCount={profile?.before} isLoading={isLoading} />
      <DigitalCard title="Активных клиентов" count={user?.current} beforeCount={user?.before} isLoading={isLoading} />
    </div>
  )
}

export default RefillStatsChart
