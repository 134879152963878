import { Form, Input } from 'antd'


const PasswordField = ({ name, label, required, disabled, rules = [], ...otherProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }, ...rules]}>
      <Input.Password size="large" disabled={disabled} {...otherProps} />
    </Form.Item>
  );
};

export default PasswordField;
