import React, { useState, useEffect } from 'react'
import { Drawer, Form as AntdForm } from 'antd'

import { Form } from 'common/components'

import "./styles.css"


const DrawerForm = ({
  name,
  title,
  width,

  showGoToDetailButton,

  pkField,
  detailPK,
  detail,
  detailIsLoading,
  onDetailLoad,
  prepareFormValues,

  savedData,
  isSaving,
  isSaved,
  errorData,

  initialValues,
  onFinish,
  onFinishFailed,
  onSuccess,

  open,
  onClose,
  submitTitle = 'Сохранить',

  children,
}) => {
  const [form] = AntdForm.useForm();
  const [formState, setFormState] = useState(Form.States.UNINITIALIZED);
  const isLoading = detailIsLoading || isSaving;

  const [formDetail, setFormDetail] = useState(null);
  const [submittable, setSubmittable] = useState(false);
  const [errorFieldNames, setErrorFieldNames] = useState(null);

  const values = AntdForm.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmittable(true);
        setErrorFieldNames(null);
      })
      .catch(({ errorFields }) => {
        if (errorFields.length) {
          setSubmittable(false);
          setErrorFieldNames(new Set(errorFields.map(item => item.name[0])));
        } else {
          setSubmittable(true);
          setErrorFieldNames(null);
        }

      });
  }, [form, values]);

  // load detail
  useEffect(() => {
    if (open && formState === Form.States.UNINITIALIZED) {
      if (detailPK) {
        setFormState(Form.States.LOADING);
        onDetailLoad && onDetailLoad(detailPK);
      } else {
        setFormState(Form.States.READY);
      }
    }
  }, [open, formState, setFormState, detailPK, onDetailLoad]);

  // set form values
  useEffect(() => {
    if (formState === Form.States.LOADING && !detailIsLoading && detail) {
      form.resetFields();

      setFormDetail(detail);

      const values = prepareFormValues ? prepareFormValues(detail) : { ...detail };
      form.setFieldsValue(values);
      setFormState(Form.States.READY);
    }
    // eslint-disable-next-line
  }, [formState, detailIsLoading, detail]);


  // success
  useEffect(() => {
    if (formState === Form.States.SUBMITED || formState === Form.States.SUBMITED_AND_REDIRECT) {
      if (isSaved && savedData) {
        const isRedirect = formState === Form.States.SUBMITED_AND_REDIRECT;
        form.resetFields();
        setFormState(Form.States.UNINITIALIZED);

        onSuccess(savedData, isRedirect);
      }
    }
    // eslint-disable-next-line
  }, [formState, isSaved, savedData]);

  const onCancel = () => {
    setFormState(Form.States.UNINITIALIZED);
    setFormDetail(null);
    form.resetFields();
    onClose();
  }

  const actionButtons =
    <Form.ActionButtons
      form={form}
      isLoading={isLoading}
      onSubmit={(nextState) => {
        form.submit();
        setFormState(nextState);
      }}
      onCancel={onCancel}
      submitTitle={submitTitle}
      showGoToDetailButton={showGoToDetailButton}
      submittable={submittable}
    />;

  return (
    <Drawer
      className="drawerForm"
      width={width || 560}
      title={title}
      open={open}
      onClose={onCancel}
      maskClosable={false}
      destroyOnClose={false}
      forceRender={true}
      //extra={actionButtons}
      footer={actionButtons}
    >
      <Form
        name={name}
        form={form}
        pkField={pkField}
        detailPK={detailPK}
        isLoading={isLoading}
        errorData={errorData}
        errorFieldNames={errorFieldNames}

        formDetail={formDetail}
        initialValues={initialValues}
        values={values}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {children}
      </Form>
    </Drawer>
  );
}

export default DrawerForm;
