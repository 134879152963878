const FULL_FORMATS = {
  'day': 'DD.MM.YY, dd',
  'month': 'MMMM YYYY',
  'year': 'YYYY'
}


const getCustomTooltip = (categories, group) => (props) => {
  const { payload, active, label } = props;
  if (!active || !payload) return null;
  const time = payload[0].payload.time;
  return (
    <div className="rounded-tremor-default text-tremor-default border bg-tremor-background shadow-tremor-dropdown border-tremor-border ">
      <div className="border-tremor-border border-b px-4 py-2">
        <p className="font-medium text-tremor-content-emphasis">
          {group === 'week' ?
            `${time.format('dd, DD.MM.YY')}-${time.add(6, 'day').format('dd, DD.MM.YY')}`
            : time.format(FULL_FORMATS[group])}
        </p>
      </div>

      {payload.map((item, idx) => (
        <div key={idx} className="px-4 py-2 space-y-1">
          <div className="flex items-center justify-between space-x-8">
            <div className="flex items-center space-x-2">
              <span className={`shrink-0 rounded-tremor-full border-2 h-3 w-3 border-tremor-background shadow-tremor-card bg-${item.color}-500 dark:bg-${item.color}-500`} />
              <p className="text-right whitespace-nowrap text-tremor-content">
                {categories[idx]}
              </p>
            </div>
            <p className="font-medium tabular-nums text-right whitespace-nowrap text-tremor-content-emphasis">
              {Intl.NumberFormat('ru').format(item.value).toString()}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default getCustomTooltip
