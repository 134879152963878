import { Card } from '@tremor/react'


const DigitalCard = ({ title, count, beforeCount, isLoading }) => {
  const diff = count - beforeCount;
  const percent = count !== 0 ? Math.abs((diff / (beforeCount || diff)) * 100).toFixed(1) : 0;

  return (
    <Card className="mx-auto max-w-xs">
      <h4 className="mb-2 text-tremor-default text-tremor-content">
        {title}
      </h4>
      <div className="text-tremor-metric font-semibold text-tremor-content-strong">
        {typeof count === 'number' && !isLoading ?
          Intl.NumberFormat('ru').format(count === 0 ? 0 : count).toString()
          :
          <div className="animate-pulse w-2/3 h-9 bg-gray-300 rounded"></div>}
      </div>
      {typeof diff === 'number' && !isLoading ?
        diff >= 0 ? (
          <div className="mt-2 flex items-center justify-between text-tremor-default text-green-500">
            <span>+{Intl.NumberFormat('ru').format(diff).toString()} ({percent}%)</span>
          </div>
        ) : (
          <div className="mt-2 flex items-center justify-between text-tremor-default text-red-500">
            <span>{Intl.NumberFormat('ru').format(diff).toString()} ({percent}%)</span>
          </div>
        )
        :
        <div className="mt-2 text-tremor-default text-green-500">
          <div className="animate-pulse w-1/2 h-4 bg-gray-300 rounded"></div>
        </div>
      }
    </Card>
  )
}


export default DigitalCard;
