import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import {
  DollarCircleOutlined, FundOutlined, AreaChartOutlined, OrderedListOutlined, WalletOutlined
} from '@ant-design/icons'

import './styles.css'



function getItem(label, key, icon, type, children) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}

const getMenuItems = (baseUrl) => {
  let items = [
    getItem('Аналитика', `${baseUrl}`, <AreaChartOutlined />),
    getItem('Счета', `${baseUrl}accounts/`, <WalletOutlined />),
    getItem('Пополнения', `${baseUrl}refills/`, <DollarCircleOutlined />),
    getItem('Транзакции', `${baseUrl}transactions/`, <FundOutlined />),
    getItem('Аукцион', `${baseUrl}auctions/`, <OrderedListOutlined />),
  ]

  return items;
}


const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const baseUrl = '/billing/';

  const allKeys = useMemo(() => {
    let keys = location.pathname.slice(1, -1).split('/').reduce((result, slug) => {
      const before = result.length > 0 ? result[result.length - 1] : '/';
      if (slug === '') {
        result.push(`/`);
      } else {
        result.push(`${before}${slug}/`);
      }
      return result
    }, []);

    if (keys.length > 1) keys = keys.slice(1);
    return keys;
  }, [location.pathname]);

  const onNavigate = ({ key }) => {
    navigate(key);
  }

  const items = getMenuItems(baseUrl);

  return (
    <Menu
      className='siteNavigation'
      mode='inline'
      theme='light'
      items={items}
      defaultOpenKeys={allKeys}
      selectedKeys={allKeys}
      onClick={onNavigate}
    />
  )
}

export default Navigation;
