import { useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Menu } from 'antd'

import './styles.css';


const AppNavigation = ({ items, fixed = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const allKeys = useMemo(() => {
    return location.pathname.slice(1, -1).split('/').reduce((result, slug) => {
      const before = result.length > 0 ? result[result.length - 1] : '/';
      if (slug === '') {
        result.push(`/`);
      } else {
        result.push(`${before}${slug}/`);
      }
      return result
    }, []);
  }, [location.pathname]);

  const onNavigate = ({ item, key }) => {
    if (item.props.context?.onClick) {
      item.props.context?.onClick();
    } else {
      navigate(key);
    }
  }

  return (
    <Menu
      className={`appNavigation ${fixed ? 'fixed' : ''}`}
      mode="inline"
      theme="light"
      items={items}
      //defaultOpenKeys={allKeys}
      defaultSelectedKeys={allKeys}
      selectedKeys={allKeys}
      onClick={onNavigate}
    />
  );
}

AppNavigation.getItem = (label, key, icon, type, children, context) => {
  return { key, icon, children, label, type, context };
}

export default AppNavigation;
