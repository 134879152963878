import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import AccountApi, { pkField } from 'store/api/accountApi'

import { AccountValue } from 'components/Account'


const DefaultColumnList = ['email', 'balance', 'is_charging'];

const FilterFields = [
  SmartFilter.getFieldConfig('is_charging', 'Вести учёт', SmartFilter.FilterBool),
];


const AccountList = ({ title, columnList, baseFilters, filterParams, onFilterChange, pageSize, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = AccountApi.endpoints.getAccountList.useLazyQuery();

  const fields = {
    email: {
      title: 'Пользователь',
      sorter: false,
      render: (val, item) => (<AccountValue detail={item} asLink={true} />),
    },
    balance: {
      title: 'Баланс',
      sorter: true,
      align: 'right',
      render: (val, item) => Intl.NumberFormat('ru').format(val).toString(),
    },
    is_charging: TableColumns.getColumnBool({ title: 'Вести учёт', fieldName: 'is_charging' }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      invalidateTags={[{ type: "AccountList" }]}

      baseFilters={baseFilters}
      filterQueryKey='account'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={false}
      hidePagination={false}
      pageSize={pageSize}
    />
  );
}

AccountList.FilterFields = FilterFields;

export default AccountList;
