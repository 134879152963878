import { useMemo } from 'react'
import { Spin } from 'antd'
import { AreaChart as TremorAreaChart } from '@tremor/react'
import dayjs from 'dayjs'

import getCustomTooltip from '../Tooltip'

import './styles.css'


const SHORT_FORMATS = {
  'day': 'DD.MM',
  'week': 'DD.MM',
  'month': 'MMM YY',
  'year': 'YYYY'
}


const AreaChart = ({ title, dataList, isLoading, group, categories, showLegend = true }) => {
  const data = useMemo(() => {
    return dataList.map((item) => {
      const dt = dayjs(item.date);
      return {
        ...item,
        time: dt,
        date: dt.format(SHORT_FORMATS[group]),
      };
    });
  }, [dataList, group]);

  const colors = categories.map(item => item.color);
  const categoryLabels = categories.map(item => item.label);

  return (
    <div className="chart">
      <div className="chart__header">
        <div className="chart__headerTitle">{title}</div>
      </div>

      <div className="chart__body">
        <Spin spinning={isLoading}>
          <TremorAreaChart
            style={{ height: '100%' }}
            data={data}
            index="date"
            yAxisWidth={70}
            categories={categories.map(item => item.name)}
            colors={colors}
            intervalType="preserveStartEnd"
            customTooltip={getCustomTooltip(categoryLabels, group)}
            valueFormatter={(number) => Intl.NumberFormat('ru').format(number).toString()}
            showLegend={showLegend}
            showAnimation
            onValueChange={(v) => console.log(v)}
            enableLegendSlider={true}
          />
        </Spin>
      </div>
    </div>
  )
}

export default AreaChart
