import { useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Tag } from 'antd'

import { getObjectSearchParams, getDataQueryString } from 'common/utils/urls'

import './styles.css'


const excludeFields = ['ordering', 'offset', 'search'];


const FilterValueItem = ({ filterValueItem, onRemoveFilterParam }) => {
  return (
    <div className="filterValue">
      <div className="filterValue__label">{filterValueItem.label}:</div>
      <div className="filterValue__values">
        {filterValueItem.values.map(([value, label]) => (
          <Tag
            key={`fv-${filterValueItem.name}-${value}`}
            closable
            onClose={e => onRemoveFilterParam(e, filterValueItem.name, value)}
          >
            {label}
          </Tag>
        ))}
      </div>
    </div>
  )
}

const DefaultDisplayValue = ({ value }) => {
  return value
}


const SmartFilterValues = ({ queryKey, onChange, fields, changeNav = true }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const objectSearchParams = useMemo(() => {
    return getObjectSearchParams(location.search, queryKey);
  }, [location.search, queryKey]);

  const fieldsByName = useMemo(() => {
    return (fields || []).reduce((res, item) => {
      res[item.fieldName] = item;
      return res;
    }, {})
  }, [fields]);

  const filters = useMemo(() => {
    let fieldNames = {};
    let result = [];
    for (let fieldName of objectSearchParams.keys()) {
      if (excludeFields.includes(fieldName) || !objectSearchParams.get(fieldName)) {
        continue
      }
      const cleanFieldName = fieldName.split('__')[0];
      const config = fieldsByName[cleanFieldName] || {};
      if (!config) continue;
      const values = objectSearchParams.getAll(fieldName);

      const ValueComponent = config.component?.DisplayValue || DefaultDisplayValue;

      if (!fieldNames[cleanFieldName]) {
        fieldNames[cleanFieldName] = 1;
        result.push({
          name: cleanFieldName,
          label: config.label || cleanFieldName,
          values: values.map(v => ([v, <ValueComponent value={v} config={config} />])),
        });
      }
    }
    return result;
  }, [fieldsByName, objectSearchParams]);

  const onRemoveFilterParam = (e, fieldName, valueToRemove) => {
    e.preventDefault();

    const values = objectSearchParams.getAll(fieldName);
    objectSearchParams.delete(fieldName);
    for (const value of values) {
      if (value !== valueToRemove) {
        objectSearchParams.append(fieldName, value);
      }
    }

    const [dataQueryString, searchString] = getDataQueryString(searchParams, objectSearchParams, queryKey);
    if (changeNav) navigate({ search: searchString }, { replace: true });

    const params = Object.fromEntries(new URLSearchParams(dataQueryString));
    onChange && onChange(params);
  };

  if (filters.length === 0) return null;

  return (
    <div className="smartFilterValuesWrap">
      {filters && filters.length > 0 &&
        <div className="smartFilterValues">
          <div className="smartFilterValues__content">
            {filters.map(filterValueItem => (
              <FilterValueItem key={`${filterValueItem.name}`} filterValueItem={filterValueItem} onRemoveFilterParam={onRemoveFilterParam} />
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default SmartFilterValues;
