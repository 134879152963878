import { ObjectValue } from 'components/Billing'


const OPERATIONS_LIST = [
  ["profile_pay", 'Списание за размещение'],
  ["job_pay", 'Списание за размещение'],
  ["salon_pay", 'Списание за размещение'],
  ["sauna_pay", 'Списание за размещение'],
  ["massage_pay", 'Списание за размещение'],
  ["auction_up_pay", 'Списание за аукцион'],
  ["auction_up_cancel", 'Отмена списания за аукцион'],
  ["refill", 'Пополнение баланса'],
]

const OPERATIONS = OPERATIONS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});


const OPERATIONS_OPTIONS = [{ value: '', label: '---'}].concat(OPERATIONS_LIST.map((item) => ({ value: item[0], label: item[1] })));


const TransactionOperation = ({ detail, style = null }) => {
  if (!detail) return null;
  let operation = OPERATIONS[detail.operationName] !== undefined ? OPERATIONS[detail.operationName] : detail.operationName;
  if ((detail.operationName === 'auction_up_pay' || detail.operationName === 'auction_up_cancel') && detail?.object?.auction) {
    operation += ` #${detail?.object?.auction}`;
  }
  return (
    <div style={style}>
      <div style={{ whiteSpace: 'nowrap', marginBottom: '4px' }}>{operation}</div>
      <ObjectValue site={detail?.site} detail={detail?.object} />
    </div>
  )
}

TransactionOperation.OPERATIONS_LIST = OPERATIONS_LIST;
TransactionOperation.OPERATIONS = OPERATIONS;
TransactionOperation.OPERATIONS_OPTIONS = OPERATIONS_OPTIONS;

export default TransactionOperation
