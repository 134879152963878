import { FormFields } from 'common/components'

const { TextareaField } = FormFields;


const MetaFieldset = () => {
  return (
    <>
      <TextareaField label="Мета-теги" name="meta" required={false} />

      <TextareaField label="Скрипты в шапке" name="scriptsTop" required={false} />

      <TextareaField label="Скрипты в футере" name="scriptsBottom" required={false} />

      <TextareaField label="robots.txt" name="robots" required={false} />
    </>
  )
}

export default MetaFieldset;
