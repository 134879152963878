import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useCitySimpleList } from 'store/api/cityApi'


export function CitySelect({ filterParams, items, required, ...otherProps }) {
  const [cities] = useCitySimpleList(filterParams, items);

  return (
    <Select
      options={cities}
      fieldNames={{ label: 'name', value: pkField }}
      optionFilterProp="name"
      {...otherProps}
    />
  )
}

const CitySelectField = ({ name, label, required, disabled, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <CitySelect required={required} disabled={disabled} {...selectProps} />
    </Form.Item>
  );
}

CitySelectField.getValue = (values) => {
  return values;
}

CitySelectField.getDisplayValue = (values, config) => {
  return values
}

export default CitySelectField
