import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { API_URL } from 'config'

const baseApiUrl = 'files/';

export const filesUploadUrl = `${API_URL}/v1/${baseApiUrl}`;

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  const pk =  detail[pkField] || detail;
  return `/files/${pk}/`
}


const FilesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFilesList: getList(builder, baseApiUrl, 'FilesList'),

    getFilesDetail: getDetail(builder, baseApiUrl, 'FilesDetail'),
    getFilesFormDetail: getDetail(builder, baseApiUrl, 'FilesFormDetail', { forEdit: true }),

    updateFiles: update(builder, baseApiUrl, pkField),

    deleteFiles: remove(builder, baseApiUrl),
    batchDeleteFiles: batchRemove(builder, baseApiUrl),
  }),
});


export default FilesApi;
