import { Form, InputNumber } from 'antd'

import "./styles.css"


const NumberField = ({ name, label, placeholder, required, disabled }) => {
  return (
    <Form.Item className="number" label={label} name={name} rules={[{ required: required }]}>
      <InputNumber size="large" disabled={disabled} placeholder={placeholder} />
    </Form.Item>
  );
};

export default NumberField;
