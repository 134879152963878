import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'site/';

export const pkField = 'pk';

export const getDetailUrl = (detail = null, pk = null) => {
  if (!detail && !pk) return '';
  const detailPK = pk || detail[pkField];
  return `/sites/${detailPK}/`
}


const SiteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSiteList: getList(builder, baseApiUrl, 'SiteList'),
    getSiteSimpleList: getList(builder, baseApiUrl, 'SiteSimpleList', { simple: true }),

    getSiteDetail: getDetail(builder, baseApiUrl, 'SiteDetail'),
    getSiteFormDetail: getDetail(builder, baseApiUrl, 'SiteFormDetail', { forEdit: true }),

    updateSite: update(builder, baseApiUrl, pkField),

    deleteSite: remove(builder, baseApiUrl),
    batchDeleteSite: batchRemove(builder, baseApiUrl),
  }),
});

export function useSiteSimpleList(filterParams, items) {
  return useLoadedItems(SiteApi.endpoints.getSiteSimpleList, filterParams, items);
}

export default SiteApi;
