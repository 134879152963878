import { DetailValue, DateTimeValue } from 'common/components'


const AuctionBetValue = ({ detail, style = null }) => {
  return (
    <DetailValue className="flex" style={style}>
      <span style={{ marginRight: '8px' }}>Ставка #{detail?.pk}:</span>
      <DateTimeValue date={detail?.time} />
    </DetailValue>
  )
}


export default AuctionBetValue
