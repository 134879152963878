import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import RefillApi, { pkField } from 'store/api/refillApi'
import { RefillActions } from 'store/features/refillSlice'

import { ClientValue } from 'components/Client'
import { AccountSelectField } from 'components/Account'


const DefaultColumnList = ['name', 'amount', 'time'];

const FilterFields = [
  SmartFilter.getFieldConfig('user', 'Счёт', AccountSelectField, { size: 'default' }),
];


const RefillList = ({ title, columnList, baseFilters, filterParams, pageSize, onFilterChange, showFilters }) => {
  const [apiGetList, apiGetListProps] = RefillApi.endpoints.getRefillList.useLazyQuery();

  const dispatch = useDispatch();

  const onEdit = (item = null) => dispatch(RefillActions.openForm({ name: 'editForm', pk: item && item[pkField] }));

  const fields = {
    name: {
      title: 'Клиент',
      sorter: false,
      render: (val, item) => (<ClientValue detail={item.user} />),
    },
    amount: { title: 'Сумма', sorter: false, width: 130, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
    time: TableColumns.getDateTime({ title: 'Дата', fieldName: 'time', width: 180 }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      invalidateTags={[{ type: "RefillList" }]}

      baseFilters={baseFilters}
      filterQueryKey='refill'
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      filterFields={FilterFields}
      showFilters={showFilters}

      rowSelection={false}
      hidePagination={false}
      pageSize={pageSize}

      onAdd={onEdit}

      summary={(dataSource) => {
        const amount = dataSource.reduce((acc, item) => acc + parseFloat(item.amount), 0)

        return (
          <>
            Итого: {Intl.NumberFormat('ru').format(amount).toString()}
          </>
        );
      }}

    />
  );
}

RefillList.FilterFields = FilterFields;

export default RefillList;
