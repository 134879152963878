import { api, getList, update } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'auction/bet/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/billing/auctions/${detail.auction}/#${detail[pkField]}`
}


const AuctionBetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAuctionBetList: getList(builder, baseApiUrl, 'AuctionBetList'),
    updateAuctionBet: update(builder, baseApiUrl, pkField),
  }),
});

export function useAuctionBetList(filterParams, items) {
  return useLoadedItems(AuctionBetApi.endpoints.getAuctionBetList, filterParams, items);
}

export default AuctionBetApi;
