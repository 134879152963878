import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import TransactionApi, { pkField } from 'store/api/transactionApi'

import { ClientValue } from 'components/Client'
import { SiteValue } from 'components/Site'
import TransactionOperation from 'components/Transaction/TransactionOperation'
import { SiteSelectField } from 'components/Site'
import { AccountSelectField } from 'components/Account'


const DefaultColumnList = ['name', 'amount', 'balanceAfter', 'operationName', 'time'];

const FilterFields = [
  SmartFilter.getFieldConfig('operationName', 'Операция', SmartFilter.FilterSelect, { options: TransactionOperation.OPERATIONS_OPTIONS }),
  SmartFilter.getFieldConfig('site', 'Сайт', SiteSelectField, { size: 'default' }),
  SmartFilter.getFieldConfig('account', 'Счёт', AccountSelectField, { size: 'default' }),
];


const TransactionList = ({ title, columnList, baseFilters, filterParams, pageSize, showFilters = false, onFilterChange }) => {
  const [apiGetList, apiGetListProps] = TransactionApi.endpoints.getTransactionList.useLazyQuery();

  const fields = {
    name: {
      title: 'Клиент',
      sorter: false,
      render: (val, item) => (<ClientValue detail={item.account.user} />),
    },
    amount: { title: 'Сумма', sorter: false, width: 130, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
    balanceAfter: { title: 'Баланс после', sorter: false, width: 130, align: 'right', render: (val) => Intl.NumberFormat('ru').format(val).toString() },
    operationName: {
      title: 'Операция',
      sorter: false,
      width: 160,
      render: (val, item) => (
        <>
          <TransactionOperation detail={item} style={{ marginBottom: '8px' }} />
          <SiteValue detail={item.site} asLink={false} style={{ fontSize: '14px' }} />
        </>
      ),
    },
    site: {
      title: 'Сайт',
      sorter: false,
      render: (val, item) => (<SiteValue detail={item.site} asLink={false} />),
    },
    time: TableColumns.getDateTime({ title: 'Дата', fieldName: 'time', width: 150 }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      invalidateTags={[{ type: "TransactionList" }]}

      baseFilters={baseFilters}
      filterQueryKey='transaction'
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      filterFields={FilterFields}
      showFilters={showFilters}

      rowSelection={false}
      hidePagination={false}
      pageSize={pageSize}

      summary={(dataSource) => {
        const amount = dataSource.reduce((acc, item) => acc + parseFloat(item.amount), 0)

        return (
          <>
            Итого: {Intl.NumberFormat('ru').format(amount).toString()}
          </>
        );
      }}

    />
  )
}

TransactionList.FilterFields = FilterFields;

export default TransactionList;
