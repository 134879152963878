import { useMemo, useState } from "react"
import { Form } from 'antd'
import ReactPhoneInput from 'react-phone-input-2'

import validations from "./validations.json"
import { COUNTRY_CODE } from 'config'

import 'react-phone-input-2/lib/style.css'
import './styles.css'


const parsePhoneNumber = (value, data, formattedNumber) => {
  const isoCode = data?.countryCode;
  const countryCodePattern = /\+\d+/;
  const areaCodePattern = /\((\d+)\)/;
  const dialCodePattern = /^\+[\d\s]+\([\d\s]+\)/;

  /** Parses the matching partials of the phone number by predefined regex patterns */
  const countryCodeMatch = formattedNumber ? (formattedNumber.match(countryCodePattern) || []) : [];
  const areaCodeMatch = formattedNumber ? (formattedNumber.match(areaCodePattern) || []) : [];
  const dialCodeMatch = formattedNumber ? (formattedNumber.match(dialCodePattern) || []) : [];

  /** Converts the parsed values of the country and area codes to integers if values present */
  const countryCode = countryCodeMatch.length > 0 ? parseInt(countryCodeMatch[0]) : null;
  const areaCode = areaCodeMatch.length > 1 ? parseInt(areaCodeMatch[1]) : null;

  /** Obtaining the dial code for comparing to the existing one - if the country mask contains an area code */
  const dialCode = dialCodeMatch.length > 0 ? dialCodeMatch[0].replaceAll(/[+\s()]/g, "") : null;
  const dialChanged = dialCode !== data?.dialCode;

  /** Parses the phone number by removing the country and area codes from the formatted value */
  const phoneNumberPattern = new RegExp(`^${countryCode}${(areaCode || "")}(\\d+)`);
  const phoneNumberMatch = value ? (value.match(phoneNumberPattern) || []) : [];
  const phoneNumber = phoneNumberMatch.length > 1 ? phoneNumberMatch[1] : null;

  /** Checks if both the area code and phone number length satisfy the validation rules */
  const rules = validations[isoCode] || { areaCode: [], phoneNumber: [] };
  const valid = (areaCode || phoneNumber) ? [
    rules.areaCode.includes((areaCode || "").toString().length),
    rules.phoneNumber.includes((phoneNumber || "").toString().length),
  ].every(Boolean) : true;

  return { countryCode, areaCode, phoneNumber, isoCode, valid, dialChanged, value };
}


const PhoneInput = ({
  value,
  style,
  country = COUNTRY_CODE,
  onlyCountries = [COUNTRY_CODE],
  className,
  size = "middle",
  onPressEnter = () => null,
  onMount: handleMount = () => null,
  onChange: handleChange = () => null,
  inputClass: inputClassProxy,
  ...reactPhoneInputProps
}) => {
  const [currentCode, setCurrentCode] = useState("");

  const rawPhone = useMemo(() => {
    if (typeof value === 'string' || value instanceof String) return value;
    const { countryCode, areaCode, phoneNumber } = { ...value };
    return [countryCode, areaCode, phoneNumber].map(v => v || "").join("");
  }, [value]);

  const inputClass = useMemo(() => {
    const suffix = { small: "sm", middle: "", large: "lg" }[size];
    const className = "ant-input" + (suffix ? " ant-input-" + suffix : "");
    return inputClassProxy ? `${className} ${inputClassProxy}` : className;
  }, [inputClassProxy, size]);

  const onChange = (value, data, event, formattedNumber) => {
    const { dialChanged, ...metadata } = parsePhoneNumber(value, data, formattedNumber);
    const code = metadata.isoCode;

    if (code !== currentCode) {
      /** Clears phone number when the country is selected manually */
      metadata.areaCode = dialChanged ? null : metadata.areaCode;
      metadata.phoneNumber = null;
      metadata.valid = false;
      setCurrentCode(code);
    }

    handleChange(metadata, event);
  }

  const onMount = (rawValue, { countryCode, ...event }, formattedNumber) => {
    const { dialChanged, ...metadata } = parsePhoneNumber(rawValue, { countryCode }, formattedNumber);
    /** Initiates the current country code with the code of initial value */
    setCurrentCode(metadata.isoCode);
    /** Initializes the existing value */
    handleChange(metadata, event);
    handleMount(metadata);
  }

  return (
    <ReactPhoneInput
      //masks={masks}
      value={rawPhone}
      enableAreaCodes
      disableSearchIcon
      /** Static properties providing dynamic behavior */
      onMount={onMount}
      onChange={onChange}
      country={country}
      onlyCountries={onlyCountries}
      inputClass={inputClass}
      /** Dynamic properties for customization */
      {...reactPhoneInputProps}
      containerStyle={style}
      containerClass={className}
      onEnterKeyPress={onPressEnter}
    />
  );
};


const PhoneField = ({ name, label, required, disabled, country = COUNTRY_CODE, onlyCountries = [COUNTRY_CODE] }) => {
  const validator = (rule, metadata) => {
    const { areaCode, phoneNumber, valid } = metadata || {};
    if ((!areaCode && !phoneNumber) || valid) {
      return Promise.resolve();
    }
    return Promise.reject("Неверный номер телефона");
  }

  return (
    <Form.Item label={label} name={name} rules={[{ required: required, validator }]}>
      <PhoneInput
        size="large"
        disabled={disabled}
        country={country}
        onlyCountries={onlyCountries}
      />
    </Form.Item>
  );
};

export default PhoneField;
