import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'staff/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/settings/staffs/${detail[pkField]}/`
}


const StaffApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStaffList: getList(builder, baseApiUrl, 'StaffList'),
    getStaffSimpleList: getList(builder, baseApiUrl, 'StaffSimpleList', { simple: true }),

    getStaffDetail: getDetail(builder, baseApiUrl, 'StaffDetail'),
    getStaffFormDetail: getDetail(builder, baseApiUrl, 'StaffFormDetail', { forEdit: true }),

    updateStaff: update(builder, baseApiUrl, pkField),

    deleteStaff: remove(builder, baseApiUrl),
    batchDeleteStaff: batchRemove(builder, baseApiUrl),
  }),
});

export function useStaffSimpleList(filterParams, items) {
  return useLoadedItems(StaffApi.endpoints.getStaffSimpleList, filterParams, items);
}

export default StaffApi;
