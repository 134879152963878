import { useState } from 'react'

import { Page } from 'common/layout'
import { DateChooser, SmartFilter } from 'common/components'
import { TransactionList } from 'components/Transaction'


const BillingTransactionPage = () => {
  const [date, setDate] = useState(null);
  const [filterParams, setFilterParams] = useState(null);

  const onDateChange = (newDate) => {
    setDate(newDate);
  }

  const baseFilters = {
    date_start: date?.format("YYYY-MM-DD"),
    date_end: date?.format("YYYY-MM-DD"),
    limit: 1000,
    ordering: '-time',
  }

  const onFilterChange = (params) => {
    setFilterParams(params)
  }

  return (
    <Page
      title="Транзакции"
      headerMiddle={
        <>
          <DateChooser onChange={onDateChange} />
          <SmartFilter
            queryKey="transaction"
            onChange={onFilterChange}
            fields={TransactionList.FilterFields}
            showSerach={false}
          />
        </>
      }
      showFilter={true}
    >
      {date && (
        <>
          <TransactionList
            baseFilters={baseFilters} 
            pageSize={1000} 
            filterParams={filterParams}
            showFilters={false}
            onFilterChange={onFilterChange}
          />
        </>
      )}
    </Page >
  )
}

export default BillingTransactionPage
