import { useState } from 'react'

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import ClientApi, { pkField, getDetailUrl } from 'store/api/clientApi'
import { ClientActions } from 'store/features/clientSlice'
import { ClientList, ClientForm } from 'components/Client'
import { PasswordChangeForm } from 'components/Auth'


const ClientListPage = () => {
  const [params, setParams] = useState(null);

  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Клиенты"

      headerMiddle={<SmartFilter queryKey="client" onChange={onFilterChange} quickFields={ClientList.FilterFields} />}
    >
      <ClientList
        showFilters={false}
        filterParams={params}
        baseFilters={{ limit: 20 }}
        pageSize={20}
        onFilterChange={onFilterChange}
      />

      <ClientForm />

      <PasswordChangeForm
        getFormDetailEndpoint={ClientApi.endpoints.getClientFormDetail}
        updateEndpoint={ClientApi.endpoints.updateClient}
        reducerName="client"
        closeFormAction={ClientActions.closeForm()}
        invalidateTags={[{ type: "ClientList" }, { type: "ClientDetail" }]}
        pkField={pkField}
        getDetailUrl={getDetailUrl}
      />
    </Page >
  )
}

export default ClientListPage
