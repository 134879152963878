import { Form, Input } from 'antd'
import slugify from '@sindresorhus/slugify'


const SlugField = ({ name, label, required, disabled, maxLength = 200, ...otherProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Input size="large" disabled={disabled} maxLength={maxLength} {...otherProps} />
    </Form.Item>
  );
}

SlugField.slugify = (value, maxLength = 200) => {
  return slugify(value).substring(0, maxLength);
}

export default SlugField;
