import { useState } from 'react'

import StatsApi from 'store/api/statsApi'
import { Page } from 'common/layout'
import { PeriodChooser } from 'common/components'
import { DigitalStats, RefillStatsList, RefillStatsChart, TransactionStatsList, TransactionStatsChart } from 'components/Billing'


const BillingPage = () => {
  const [apiGetRefillStats, apiGetRefillStatsProps] = StatsApi.endpoints.getRefillStats.useLazyQuery();
  const [apiGetTransactionStats, apiGetTransactionStatsProps] = StatsApi.endpoints.getTransactionStats.useLazyQuery();

  const [period, setPeriod] = useState(null);

  const onPeriodChange = ({ period, dateStart, dateEnd, group }) => {
    console.log('onPeriodChange', { period, dateStart, dateEnd, group });
    setPeriod({ period, dateStart, dateEnd, group });

    const filterParams = {
      'date_start': dateStart.format("YYYY-MM-DD"),
      'date_end': dateEnd.format("YYYY-MM-DD"),
      'group': group,
    }
    apiGetRefillStats({ params: { ...filterParams, include_users: '1' } });
    apiGetTransactionStats({ params: { ...filterParams, include_sites: '1' } });
  }

  const refillDataList = apiGetRefillStatsProps.data || [];
  const refillIsLoading = apiGetRefillStatsProps.isFetching;
  const transactionDataList = apiGetTransactionStatsProps.data || [];
  const transactionIsLoading = apiGetTransactionStatsProps.isFetching;

  return (
    <Page
      title="Биллинг"
      headerMiddle={
        <PeriodChooser onChange={onPeriodChange} />
      }
    >
      {period?.dateStart && period?.dateEnd && (
        <>
          <DigitalStats dateStart={period.dateStart.format("YYYY-MM-DD")} dateEnd={period.dateEnd.format("YYYY-MM-DD")} />

          <div className="grid grid-cols-2 gap-4 mb-8">
            <div>
              <RefillStatsList
                title="Пополнения"
                dataList={refillDataList}
                isLoading={refillIsLoading}
                dateStart={period?.dateStart.format("YYYY-MM-DD")}
                dateEnd={period?.dateEnd.format("YYYY-MM-DD")}
                group={period?.group}
              />
            </div>

            <div>
              <RefillStatsChart
                title="График пополнений"
                dataList={refillDataList}
                isLoading={refillIsLoading}
                dateStart={period?.dateStart.format("YYYY-MM-DD")}
                dateEnd={period?.dateEnd.format("YYYY-MM-DD")}
                group={period?.group}
                withTransactions={false}
                showLegend={false}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <TransactionStatsList
                title="Списания"
                dataList={transactionDataList}
                isLoading={transactionIsLoading}
                dateStart={period?.dateStart.format("YYYY-MM-DD")}
                dateEnd={period?.dateEnd.format("YYYY-MM-DD")}
                group={period?.group}
              />
            </div>

            <div>
              <TransactionStatsChart
                title="График списаний"
                dataList={transactionDataList}
                isLoading={transactionIsLoading}
                dateStart={period?.dateStart.format("YYYY-MM-DD")}
                dateEnd={period?.dateEnd.format("YYYY-MM-DD")}
                group={period?.group}
                withTransactions={false}
              />
            </div>
          </div>
        </>
      )}
    </Page >
  )
}

export default BillingPage
