// import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'common/layout'
import { OnOff } from 'common/components'

import ClientApi, { pkField } from 'store/api/clientApi'
// import { ClientActions } from 'store/features/clientSlice'
import { ClientForm, ClientDetail } from 'components/Client'


const ClientDetailPage = () => {
  const { clientPK } = useParams();

  const detailPK = clientPK;

  //const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = ClientApi.endpoints.getClientDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ClientApi.endpoints.updateClient.useMutation();
  const [apiDelete, apiDeleteProps] = ClientApi.endpoints.deleteClient.useMutation();

  const detail = apiGetDetailProps.data;

  /*const onEdit = () => {
    dispatch(ClientActions.openForm({ name: 'editForm', pk: detailPK }))
  };*/

  const activeSwitch = (
    <OnOff
      name="Client"
      detail={detail}
      pkField={pkField}
      fieldName="is_active"
      apiUpdate={apiUpdate}
      apiUpdateProps={apiUpdateProps}
    />
  );

  const detailTitle = detail?.email ? `«${detail?.email}»` : '';

  return (
    <DetailPage
      title={`Клиент ${detailTitle}`}
      detailPK={detailPK}
      detail={detail}

      basePath='/sites/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      actions={[
        { label: 'Активно', component: activeSwitch },
        //{ icon: 'edit', label: 'Редактировать', type: 'primary', onClick: () => onEdit() },
      ]}
      otherActions={null}
    >
      <ClientDetail detail={detail} />

      <ClientForm showGoToDetailButton={false} />
    </DetailPage>
  );
}

export default ClientDetailPage
