import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useClientSimpleList } from 'store/api/clientApi'
import ClientValue from '../ClientValue'


export function ClientSelect({ filterParams, items, required, ...otherProps }) {
  const [clients] = useClientSimpleList(filterParams, items);

  const options = clients.map(item => ({
    ...item,
    title: <ClientValue detail={item} asLink={false} showSecondary={false} />,
    searchString: `${item.email} ${item.phone} ${item.first_name} ${item.last_name}`
  }));

  return (
    <Select
      options={options}
      fieldNames={{ value: pkField }}
      optionFilterProp="searchString"
      optionRender={option => <ClientValue detail={option.data} asLink={false} />}
      labelRender={({ title }) => title}
      {...otherProps}
    />
  )
}


const ClientSelectField = ({ name, label, required, disabled, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <ClientSelect required={required} disabled={disabled} {...selectProps} />
    </Form.Item>
  );
}

ClientSelectField.getValue = (values) => {
  return values;
}

ClientSelectField.getDisplayValue = (values, config) => {
  return values
};

export default ClientSelectField
