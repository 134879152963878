import { AreaChart } from 'common/components/Charts'

import { useRefillStats } from 'store/api/statsApi'


const RefillStatsChart = ({ title, dateStart, dateEnd, group, dataList, isLoading, withTransactions = false }) => {
  const filterParams = {
    'date_start': dateStart,
    'date_end': dateEnd,
    'group': group,
    'with_transactions': withTransactions ? '1' : '0',
  }
  const [rows, isFetching] = useRefillStats(filterParams, dataList);

  return (
    <AreaChart
      title={title}
      dataList={rows}
      isLoading={isLoading || isFetching}
      group={group}
      categories={[
        { name: 'amount', label: 'Пополнение', color: 'emerald' },
      ]}
      showLegend={false}
    />
  )
}

export default RefillStatsChart
