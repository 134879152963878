import { Row, Col } from 'antd'

import { FormFields } from 'common/components'

const { TextField, ImageUploadField, SelectField } = FormFields;


const WatermarkOptions = [
  { value: 'ce', label: 'По центру' },
  { value: 'no', label: 'Вверху слева' },
  { value: 'nw', label: 'Вверху по центру' },
  { value: 'ne', label: 'Вверху справа' },
  { value: 'ea', label: 'Справа посередине' },
  { value: 'sw', label: 'Внизу слева' },
  { value: 'so', label: 'Внизу по центру' },
  { value: 'se', label: 'Внизу справа' },
  { value: 'we', label: 'Слева посередине' },

  { value: 'ce_45', label: 'По диагонали сверху' },
  { value: 'ce_-45', label: 'По диагонали снизу' },
];


const WatermarkFieldset = () => {
  return (
    <>
      <SelectField label="Позиция" name="watermarkPosition" required={false} options={WatermarkOptions} />

      <Row gutter={32}>
        <Col span={12}>
          <TextField label="Водяной знак: текст" name="watermarkText" required={false} />
        </Col>

        <Col span={12}>
          <ImageUploadField
            label="Водяной знак: картинка" name="watermarkImage" data={{ category: 'watermark' }} required={false}
            uploadText="Загрузить" multiple={false}
          />
        </Col>
      </Row>
    </>
  )
}

export default WatermarkFieldset;
