import { useParams } from "react-router-dom"

import { DetailPage } from 'common/layout'
import { DateTimeValue } from "common/components"

import AuctionApi from 'store/api/auctionApi'
import { AuctionDetail } from 'components/Auction'
import { AuctionBetList } from 'components/AuctionBet'


const AuctionDetailPage = () => {
  const { auctionPK } = useParams();
  const detailPK = auctionPK;

  const [apiGetDetail, apiGetDetailProps] = AuctionApi.endpoints.getAuctionDetail.useLazyQuery();

  const detail = apiGetDetailProps.data;

  const detailTitle = detail?.time ? `#${detailPK}: ${DateTimeValue.getFormatted(detail?.time)}` : `#${detailPK}`;

  return (
    <DetailPage
      title={`Аукцион ${detailTitle}`}
      detailPK={detailPK}
      detail={detail}

      basePath='/billing/auctions/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}

      otherActions={null}
    >
      <AuctionDetail detail={detail} />

      <AuctionBetList
        title="Ставки"
        site={detail?.site}
        baseFilters={{ auction: detailPK, limit: 1000, ordering: '-betAmount' }}
        pageSize={1000}
        showFilters={false}
      />
    </DetailPage>
  );
}

export default AuctionDetailPage
