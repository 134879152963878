import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'client/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/clients/${detail[pkField]}/`
}


const ClientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClientList: getList(builder, baseApiUrl, 'ClientList'),
    getClientSimpleList: getList(builder, baseApiUrl, 'ClientSimpleList', { simple: true }),

    getClientDetail: getDetail(builder, baseApiUrl, 'ClientDetail'),
    getClientFormDetail: getDetail(builder, baseApiUrl, 'ClientFormDetail', { forEdit: true }),

    updateClient: update(builder, baseApiUrl, pkField),

    deleteClient: remove(builder, baseApiUrl),
    batchDeleteClient: batchRemove(builder, baseApiUrl),
  }),
});

export function useClientSimpleList(filterParams, items) {
  return useLoadedItems(ClientApi.endpoints.getClientSimpleList, filterParams, items);
}

export default ClientApi;
