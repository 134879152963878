import { useMemo } from 'react'

import { AreaChart } from 'common/components/Charts'

import { useRefillStats } from 'store/api/statsApi'


const COLORS = ['rose', 'teal', 'yellow', 'fuchsia', 'pink', 'purple', 'violet', 'indigo', 'blue', 'cyan', 'green', 'lime', 'orange', 'red'];


const TransactionStatsChart = ({ title, dateStart, dateEnd, group, dataList, isLoading, withTransactions = false }) => {
  const filterParams = {
    'date_start': dateStart,
    'date_end': dateEnd,
    'group': group,
    'with_transactions': withTransactions ? '1' : '0',
  }
  const [rows, isFetching] = useRefillStats(filterParams, dataList);

  const allSites = useMemo(() => {
    return Object.keys(
      rows.reduce((acc, item) => {
        const _sites = item.sites.reduce((acc2, site) => {
          acc2[site.domain] = 1;
          return acc2;
        }, {});
        acc = { ...acc, ..._sites };
        return acc
      }, {}));
  }, [rows]);

  const rows2 = useMemo(() => {
    return rows.map((item) => {
      const itemSites = item.sites.reduce((acc, site) => {
        acc[site.domain] = site.amount;
        return acc;
      }, {});

      const _sites = allSites.reduce((acc, domain) => {
        acc[domain] = itemSites[domain] || 0;
        return acc;
      }, {});

      return {
        ...item,
        ..._sites,
      };
    });
  }, [rows]);

  return (
    <AreaChart
      title={title}
      dataList={rows2}
      isLoading={isLoading || isFetching}
      group={group}
      categories={
        allSites.map((domain, idx) => ({
          name: domain,
          label: domain,
          color: COLORS[idx],
        }))
      }
    />
  )
}

export default TransactionStatsChart
