import { Row, Col } from 'antd'

import { FormFields } from 'common/components'

const { ImageUploadField } = FormFields;


const IconsFieldset = () => {
  return (
    <Row gutter={32}>
      <Col span={8}>
        <ImageUploadField
          label="Логотип" name="logo" data={{ category: 'site' }} required={false}
          uploadText="Загрузить" multiple={false}
        />
      </Col>

      <Col span={8}>
        <ImageUploadField
          label="Фавиконка" name="favicon" data={{ category: 'site' }} required={false}
          uploadText="Загрузить" multiple={false}
        />
      </Col>
    </Row>
  )
}

export default IconsFieldset;
