import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import StaffApi, { pkField, getDetailUrl } from 'store/api/staffApi'
import { StaffActions } from 'store/features/staffSlice'
import { StaffList, StaffForm } from 'components/Staff'
import { PasswordChangeForm } from 'components/Auth'


const StaffListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(StaffActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Администраторы"

      actions={[
        { icon: 'add', label: 'Добавить', type: 'primary', onClick: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="staff" onChange={onFilterChange} fields={StaffList.FilterFields} />}
    >
      <StaffList showFilters={false} filterParams={params} onFilterChange={onFilterChange} />

      <StaffForm />

      <PasswordChangeForm
        getFormDetailEndpoint={StaffApi.endpoints.getStaffFormDetail}
        updateEndpoint={StaffApi.endpoints.updateStaff}
        reducerName="staff"
        closeFormAction={StaffActions.closeForm()}
        invalidateTags={[{ type: "StaffList" }, { type: "StaffDetail" }]}
        pkField={pkField}
        getDetailUrl={getDetailUrl}
      />
    </Page >
  )
}

export default StaffListPage
