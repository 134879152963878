import { Link, useLocation } from 'react-router-dom'

import "./styles.css"


const DetailValue = ({ className, style, url, isBack = true, replace = false, children }) => {
  const location = useLocation();

  const state = isBack ? { backUrl: location.pathname, backSearch: location.search } : null;

  const cls = className || '';

  if (url) {
    return (
      <Link className={`detailLink ${cls}`} style={style} to={url} state={state} replace={replace}>{children}</Link>
    )
  }

  return (
    <span className={`detailValue ${cls}`} style={style}>{children}</span>
  );
}

export default DetailValue
