import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'store/api/staffApi'


const initialState = {
  ...baseModelState,
}


export const staff = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateStaff);
  },
});

export default staff.reducer;

export const StaffActions = staff.actions;
